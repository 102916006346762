import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import dynamic from 'next/dynamic'
// MUI
import { EditOutlined, InfoOutlined } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
// Helpers and Components
import { baseUrl } from '../../src/helpers/helper'
import { gCSDocDownloadLinkQuery } from '../../src/gql/googleCloudStorageGQL'

const ReactQuill = dynamic(
  () => import('react-quill'),
  { ssr: false }
)

const thisFile = 'Supporticle.js ' // eslint-disable-line no-unused-vars

const Supporticle = (props) => {
  const {
    supporticle,
    permissions,
    openInNewTab,
    onShowSupporticleEditOpen
  } = props

  const [fireGCSDownloadLinkQuery] = useLazyQuery(gCSDocDownloadLinkQuery)

  const [glossaryView] = useState(openInNewTab)
  const [showInfo, setShowInfo] = useState(false)
  const [downloadLinks, setDownloadLinks] = useState({})
  const showSupporticle = supporticle.categories.includes('Pinned') // For showing the supporticle if it is pinned in the drawer

  // Gets the download links for the attachments for the supporticle
  useEffect(() => {
    const getDownloadLinks = async () => {
      const attachmentLinks = {}
      for (const attachment of supporticle.attachmentsForContent) {
        const { data } = await fireGCSDownloadLinkQuery({
          variables: {
            input: {
              fileName: `supporticleAttachments/${supporticle.slug}/${attachment.fileName}`
            }
          }
        })
        attachmentLinks[attachment.fileName] = data.gCSDocDownloadLink
      }
      setDownloadLinks(attachmentLinks)
    }
    if (supporticle?.attachmentsForContent && !Object.keys(downloadLinks).length) {
      getDownloadLinks()
    }
  }, [fireGCSDownloadLinkQuery, supporticle])

  return (
    <Card key={supporticle.slug} style={{ margin: '1em', overflow: 'scroll', height: openInNewTab ? 500 : 800 }}>
      {glossaryView && !showSupporticle &&
      <CardContent>
        <Grid
          container
          spacing={0}
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item xs={openInNewTab ? 11 : 10}>
            <Typography variant='subtitle1'><b>{supporticle.term}: </b>{supporticle.definition}</Typography>
          </Grid>

          {openInNewTab &&
          <Grid item xs={2}>
            <Typography variant='subtitle1'><b>Supporticle:</b></Typography>
            <Link
              href={baseUrl + '/support/' + supporticle.slug}
              rel='noreferrer'
              target='_blank'
              underline="hover">
              {supporticle.articleTitle}
            </Link>
          </Grid>}
          {supporticle?.attachmentsForContent?.length &&
          <Grid item xs={12}>
            <Typography variant='subtitle1'><b>Attachments:</b><br />{supporticle.attachmentsForContent.map(attachment => (
              <>
                <Link
                  key={attachment.fileName}
                  href={downloadLinks[attachment.fileName]}
                  rel='noreferrer'
                  target='_blank'
                  underline="hover">
                  {attachment.fileName}
                </Link>
                <br />
              </>
            ))}</Typography>
          </Grid>
          }

        </Grid>
      </CardContent>}

      {(!glossaryView || showSupporticle) &&
        <CardContent>
          <Toolbar variant='dense'>

            {permissions.includes('update:supporticle') && !glossaryView &&
            <Button
              size='small'
              style={{ left: '85%' }}
              onClick={() => onShowSupporticleEditOpen(supporticle)}
            >
              <EditOutlined color='secondary' />
            </Button>}
            {!glossaryView &&
            <Button
              size='small'
              style={{ left: '85%' }}
              onClick={() => setShowInfo(!showInfo)}
            >
              <InfoOutlined color='secondary' />
            </Button>}

          </Toolbar>

          {showInfo &&
          <Grid
            container
            spacing={0}
            direction='column'
            justifyContent='space-evenly'
            alignItems='flex-start'
          >
            <Grid item xs>
              <Typography variant='caption'><b>Slug: </b>{supporticle.slug}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='caption'><b>Author: </b>{supporticle.author}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='caption'><b>Created: </b>{new Date(supporticle.createdAt).toLocaleDateString('en-US') + ' ' + new Date(supporticle.createdAt).toLocaleTimeString('en-US')}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant='caption'><b>Last updated: </b>{new Date(supporticle.updatedAt).toLocaleDateString('en-US') + ' ' + new Date(supporticle.updatedAt).toLocaleTimeString('en-US')}</Typography>
            </Grid>
          </Grid>}

          <Typography variant='subtitle1'><b>{supporticle.term}: </b>{supporticle.definition}</Typography>
          {supporticle?.attachmentsForContent?.length &&
            <Typography variant='subtitle1'><b>Attachments:</b><br />{supporticle.attachmentsForContent.map(attachment => (
              <>
                <Link
                  key={attachment.fileName}
                  href={downloadLinks[attachment.fileName]}
                  rel='noreferrer'
                  target='_blank'
                  underline="hover">
                  {attachment.fileName}
                </Link>
                <br />
              </>
            ))}</Typography>
          }
          <Typography variant='h5' align='center' style={{ marginTop: '1em' }}>{supporticle.articleTitle}</Typography>

          {/* read-only, no-toolbar instance of ReactQuill to display all styling, formatting, fonts, etc */}
          <ReactQuill
            modules={{ toolbar: false }}
            id='articleText'
            className='qa-textField-supporticle-text'
            label='Support Article Text'
            theme='snow'
            name='articleText'
            value={supporticle.articleText}
            readOnly
          />

        </CardContent>}
    </Card>
  )
}

export default Supporticle
