import { useState, useEffect, useRef } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import dynamic from 'next/dynamic'
// MUI Core
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import { Delete, Save, Close, AttachFile } from '@mui/icons-material'
import { DropzoneDialog } from 'react-mui-dropzone'// eslint-disable-next-line no-unused-vars
// Helpers and Components
import { supportPermissions } from '../../src/dictionaries/supportDict'
import useDeBounce from '../../src/hooks/deBounceHook'
import { consoleDev, consoleError, jsonStr, jsonPar, moment, _, baseContext, scrubTypename } from '../../src/helpers/helper' // eslint-disable-line no-unused-vars
import {
  createSupporticleMutation, updateSupporticleMutation,
  deleteSupporticleMutation, uniqueSupporticleSlugQuery,
  quillFormats, quillModules
} from '../../src/helpers/supportHelper'
import { gCSAttachmentUploadFilesQuery } from '../../src/gql/googleCloudStorageGQL'
import themeBuilder from '../../src/theme'
const theme = themeBuilder()

const clientWindow = (typeof window !== 'undefined') ? window : undefined

const ReactQuill = dynamic(
  () => import('react-quill'),
  { ssr: false }
)

const thisFile = 'components/Support/SupporticleEdit.js'

const SupporticleEdit = (props) => {
  const {
    originalSupporticle,
    onShowSupporticleEditClose,
    selectableCategories
  } = props

  // Queries and Mutations
  const [createSupporticle, { loading: createLoading, error: createError, data: createdSupporticleData }] = useMutation(createSupporticleMutation)
  const [updateSupporticle, { loading: updateLoading, error: updateError, data: updatedSupporticleData }] = useMutation(updateSupporticleMutation)
  const [deleteSupporticle, { loading: deleteLoading, error: deleteError }] = useMutation(deleteSupporticleMutation)
  const [getUniqueSupporticleSlug, { loading: uniqueSlugLoading, data: uniqueSlugData }] = useLazyQuery(uniqueSupporticleSlugQuery)
  const [fireUploadLinksQuery, { data: attachmentLinksData }] = useLazyQuery(gCSAttachmentUploadFilesQuery)
  const [getAttachmentLinks, { data: getAttachmentLinksData }] = useLazyQuery(gCSAttachmentUploadFilesQuery) // Used for a fresh start on the data so we don't mess up the for loop

  // State variables
  const [supporticle, setSupporticle] = useState(_.cloneDeep(originalSupporticle) || {})
  const isForCreate = _.isEmpty(supporticle)
  const [slug, setSlug] = useState(supporticle?.slug || '')
  const [stringSlugValue, setStringSlugValue] = useState(supporticle?.slug || '')
  const deBouncedSlug = useDeBounce(stringSlugValue, process.env.DEBOUNCE_DELAY)
  const firstLoad = useRef(true)
  const supporticleUpdateUnsavedChanges = useRef(false)
  const [supporticleUpdate, setSupporticleUpdate] = useState({
    term: supporticle?.term || '',
    definition: supporticle?.definition || '',
    articleTitle: supporticle?.articleTitle || '',
    categories: supporticle?.categories || '',
    subCategories: supporticle?.subCategories || [],
    author: supporticle?.author || '',
    userPermissions: supporticle?.userPermissions || [],
    attachmentsForContent: supporticle?.attachmentsForContent || []
  })
  const [articleText, setArticleText] = useState(supporticle?.articleText || '')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showErrorDialog, setShowErrorDialog] = useState(false)
  const [errorDialogMessage, setErrorDialogMessage] = useState('')
  const [attachmentDialogOpen, setAttachmentDialogOpen] = useState(false)
  const [attachments, setAttachments] = useState(supporticleUpdate?.attachmentsForContent)
  const showLocal = baseContext === 'dev'
  const showStaging = baseContext === 'sta'
  const dialogBgColor = (showLocal ? theme.palette.local[theme.palette.mode] : (showStaging ? theme.palette.staging[theme.palette.mode] : theme.palette.primary[theme.palette.mode]))

  // Handles the changes made to the MUI components
  const handleChange = name => event => {
    if (['categories', 'subCategories'].includes(name)) {
      const category = (event.target.value === undefined || event.target.value === 0) ? event.target.textContent : event.target.value
      setSupporticleUpdate({ ...supporticleUpdate, [name]: category })
    } else {
      setSupporticleUpdate({ ...supporticleUpdate, [name]: event.target.value })
    }
  }

  // Handles the changes made to the quill editor
  const handleArticleTextChange = (value) => {
    if (articleText !== value) {
      setArticleText(value)
    }
    supporticleUpdateUnsavedChanges.current = true
  }

  // Checks if the size of the attachment isn't to big to save
  const sizeOk = () => {
    if (articleText) { // only need to check size if articleText is filled in
      if (clientWindow) { // blob only works client side
      const articleTextSize = new Blob([articleText]).size // eslint-disable-line
        if (articleTextSize > 1000000) {
          setShowErrorDialog(true)
          setErrorDialogMessage('Sorry, that article is too big. If you included an image over 1 MB, you will need to shrink it or host it elsewhere.')
          return false
        } else {
          setShowErrorDialog(false) // probably already false, but be sure
          return true
        }
      }
    } else { // no articleText so pass through
      return true
    }
  }

  // Handles saving attachments to Google Cloud Storage
  const handleAttachmentSave = async () => {
    if (attachments.length > 0) {
      const inputArray = []
      for (const attachment of attachments) {
        inputArray.push({
          fileName: `supporticleAttachments/${slug}/${attachment.name || attachment.fileName}`,
          contentType: attachment.type || attachment.contentType,
          name: attachment.name || attachment.fileName,
          size: attachment.size || attachment.fileSize
        })
      }
      const result = await fireUploadLinksQuery({
        variables: {
          input: inputArray
        },
        fetchPolicy: 'no-cache'
      })
      return scrubTypename(result.data.gCSAttachmentUploadLinks)
    }
    return []
  }

  // Handles the creation of a new supporticle
  const handleCreate = async () => {
    if (!slug) {
      window && window.alert('Slug Required')
      return
    }
    await handleAttachmentSave()
      .then(async attachmentsForSupporticle => {
        sizeOk(articleText) && createSupporticle({
          variables: {
            input: {
              slug: slug,
              term: supporticleUpdate.term || '',
              definition: supporticleUpdate.definition || '',
              articleTitle: supporticleUpdate.articleTitle || '',
              categories: supporticleUpdate.categories || [],
              subCategories: supporticleUpdate.subCategories || [],
              author: supporticleUpdate.author || '',
              articleText: articleText || '',
              userPermissions: supporticleUpdate.userPermissions || [],
              attachmentsForContent: attachmentsForSupporticle || []
            }
          }
        })
        supporticleUpdateUnsavedChanges.current = false
      })
  }

  // Handles the update of an existing supporticle
  const handleUpdate = async () => {
    await handleAttachmentSave()
      .then(async attachmentsForSupporticle => {
        sizeOk(articleText) && updateSupporticle({
          variables: {
            input: {
              id: supporticle.id,
              slug: slug,
              term: supporticleUpdate.term || '',
              definition: supporticleUpdate.definition || '',
              articleTitle: supporticleUpdate.articleTitle || '',
              categories: supporticleUpdate.categories || [],
              subCategories: supporticleUpdate.subCategories || [],
              author: supporticleUpdate.author || '',
              articleText: articleText || '',
              userPermissions: supporticleUpdate.userPermissions || [],
              attachmentsForContent: attachmentsForSupporticle || []
            }
          }
        })
      })
    supporticleUpdateUnsavedChanges.current = false
  }

  // Handles the deletion of an existing supporticle
  const handleDelete = () => {
    deleteSupporticle({
      variables: { slug: supporticle.slug }
    })
    setShowDeleteDialog(false)
    onShowSupporticleEditClose()
  }

  // Handles the closing of the dialog and returns the support table
  const handleClose = () => {
    supporticleUpdateUnsavedChanges.current && setShowSaveDialog(true)
    !supporticleUpdateUnsavedChanges.current && onShowSupporticleEditClose()
  }

  // set supporticleUpdateUnsavedChanges true if any changes have been made
  useEffect(() => {
    supporticleUpdateUnsavedChanges.current = true
  }, [supporticleUpdate])

  // if this isn't the firstLoad, and user has changed slug,
  // check it for uniqueness and return formatted slug (lower cased, with underscores for spaces)
  useEffect(() => {
    !firstLoad.current &&
    (deBouncedSlug !== slug) &&
    (deBouncedSlug !== originalSupporticle?.slug) &&
    deBouncedSlug.length > 2 &&
    getUniqueSupporticleSlug({ variables: { slug: deBouncedSlug }, fetchPolicy: 'no-cache' })
    firstLoad.current = false
  }, [firstLoad, deBouncedSlug, slug, originalSupporticle?.slug, getUniqueSupporticleSlug])

  // if we got uniqueSlugData back from our query, set state with it
  useEffect(() => {
    if (uniqueSlugData && uniqueSlugData.uniqueSupporticleSlug) {
      setSlug(uniqueSlugData.uniqueSupporticleSlug.slug)
      setStringSlugValue(uniqueSlugData.uniqueSupporticleSlug.slug)
      supporticleUpdateUnsavedChanges.current = true
    }
  }, [uniqueSlugData])

  // set supporticleUpdateUnsavedChanges false on first load
  useEffect(() => {
    supporticleUpdateUnsavedChanges.current = false
  }, [])

  // set the current supporticle with the return from create mutation
  useEffect(() => {
    if (createdSupporticleData) {
      setSupporticle(createdSupporticleData.createSupporticle)
    }
  }, [createdSupporticleData])

  // set the current supporticle with the return from update mutation
  useEffect(() => {
    updatedSupporticleData && setSupporticle(updatedSupporticleData.updateSupporticle)
  }, [updatedSupporticleData])

  // check for errors, set flag to show dialog if we get one
  useEffect(() => {
    (createError || updateError || deleteError) && setShowErrorDialog(true)
    createError && setErrorDialogMessage('Error creating Support Article. Details: ' + createError)
    updateError && setErrorDialogMessage('Error updating Support Article. Details: ' + updateError)
    deleteError && setErrorDialogMessage('Error deleting Support Article. Details: ' + deleteError)
  }, [createError, updateError, deleteError])

  // UPLOAD
  useEffect(() => {
    const upload = async (fileObject, link) => {
      try {
        // convert File object into an ArrayBuffer ("a generic, fixed-length raw binary data buffer")
        const ab = await fileObject.arrayBuffer()
        const byteCount = ab.byteLength
        const CHUNK_SIZE = 5e+6 // 5MB -- needs to be "in multiples of 256 KB (256 x 1024 bytes) in size, except for the final chunk that completes the upload"
        let startByte = 0 // initial value for first chunk, zero-indexed, so the first byte is 0
        let endByte = startByte + CHUNK_SIZE - 1 // initial value for first chunk, zero-indexed, so if we're doing the first 256 bytes, this will be 255
        if (endByte > byteCount) { // if we're on the last chunk already, make the endByte the byteCount - 1
          endByte = byteCount - 1
        }
        /**
          "you should retry any requests that return the following status codes: 408 Request Timeout,
          500 Internal Server Error, 502 Bad Gateway, 503 Service Unavailable, 504 Gateway Timeout"
        **/
        const retryWithSameURI = [408, 500, 502, 503, 504]
        const MAX_RETRIES = 10
        let retryCount = 0

        /**
       * This will recursively re-run until it either throws an error (in the case of
       * hitting MAX_RETRIES), or it returns when it gets an 'ok' (any 200-299) response.
       */
        const uploadChunk = async () => {
          try {
            const chunk = ab.slice(startByte, endByte + 1) // the Content-Range needs the end byte to be *inclusive*, but .splice() needs it to be *exclusive*, so we add 1 to endByte here
            await window.fetch(link, {
              headers: { // note that Content-Length gets auto-populated by fetch, and Content-Type was set when we requested the upload URI
                'Content-Range': `bytes ${startByte}-${endByte}/${ab.byteLength}`
              },
              method: 'PUT',
              body: chunk
            }).then(async (response) => {
              if (response.ok) { // 200-299 so this chunk (the last or only chunk) uploaded and we're done
                const responseJSON = await response.json()
                return responseJSON
              }

              if (response.status === 308) { // this chunk uploaded but there's more, so move on to the next one
                startByte = parseInt(response.headers.get('range').split('-')[1])
                endByte = startByte + CHUNK_SIZE - 1
                if (endByte > byteCount) { // if we're on the last chunk, make the endByte the byteCount - 1
                  endByte = byteCount - 1
                }
                await uploadChunk()
              }

              if (retryWithSameURI.includes(response.status)) { // there was a problem with this chunk and we need to retry
                // wait an increasing amount of time and try the same chunk again (aka "truncated exponential backoff") MAX_RETRIES times before giving up
                // wait time is semi-random "to avoid cases where many clients get synchronized by some situation and all retry at once, sending requests in synchronized waves"
                if (retryCount < MAX_RETRIES) {
                  setTimeout(await uploadChunk(), retryCount * 1000 + (1000 / Math.random()))
                  retryCount++
                }
              }

              if ((response.status === 400 || response.status === 404) && retryCount < MAX_RETRIES) {
                try {
                  // start over completely, getting a new URI to upload to
                  await getAttachmentLinks({
                    variables: {
                      input: [{
                        fileName: `supporticleAttachments/${slug}/${fileObject.name || fileObject.fileName}`,
                        contentType: fileObject.type || fileObject.contentType
                      }]
                    },
                    fetchPolicy: 'no-cache'
                  })
                  if (getAttachmentLinksData?.gCSAttachmentUploadLinks?.length) {
                    retryCount++
                    await upload(fileObject, getAttachmentLinksData?.gCSAttachmentUploadLinks[0])
                  }
                } catch (error) {
                  consoleError(thisFile, 'GraphQL error querying for new upload URI: ', error)
                  if (retryCount < MAX_RETRIES) {
                    setTimeout(async () => await uploadChunk(), retryCount * 1000 + (1000 / Math.random()))
                    retryCount++
                  }
                }
              }

              if (retryCount === MAX_RETRIES) throw new Error(`Unable to upload file after ${retryCount} attempts.`)
            })
          } catch (error) {
            consoleError(thisFile, 'uploadChunk error: ', error)
            if (retryCount < MAX_RETRIES) {
              setTimeout(() => uploadChunk(), retryCount * 1000 + (1000 / Math.random()))
              retryCount++
            }
          }
        }
        await uploadChunk() // upload the first chunk, kicking off the recursion that will upload (or try to) all chunks and return JSON
      } catch (error) {
        consoleError(thisFile, 'upload error: ', error)
      }
    }
    // Filename is urlEncoded when on the link
    // const encodedFileName = String(attachments?.length && encodeURIComponent(attachments[0].name || attachments[0].fileName))
    // // Note that although RFC3986 reserves "!", RFC5987 does not,
    // // so we do not need to escape it
    //   .replace(/[']/g, escape) // i.e., %27

    let encodedFileNameIncluded = false
    for (let i = 0; i < attachments.length; i++) {
      const encodedFileName = String(attachments?.length && encodeURIComponent(attachments[i].name || attachments[i].fileName))
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
        .replace(/[']/g, escape) // i.e., %27

      if (encodedFileNameIncluded) continue
      encodedFileNameIncluded = attachmentLinksData?.gCSAttachmentUploadLinks[i]?.link.includes(encodedFileName)
    }
    if (encodedFileNameIncluded) {
      // if we have files and the links include their names (even if their names have spaces) then upload
      for (const [index, attachment] of attachmentLinksData.gCSAttachmentUploadLinks.entries()) {
        try {
          // checks to make sure its still a file just attached rather than a attachment we have already stored on the supporticle
          if (attachments[index].path) {
            upload(attachments[index], attachment.link)
          }
        } catch (error) {
          consoleError(thisFile, 'upload error: ', error)
        }
      }
      // setAttachments([])
    }
  }, [attachmentLinksData, attachments])

  return <>
    <AppBar position='fixed'>
      <Toolbar style={{ backgroundColor: `${dialogBgColor}` }}>
        <Tooltip title='Close' placement='bottom'>
          <IconButton
            edge='start'
            className='qa-button-supporticle-close'
            color='inherit'
            onClick={handleClose}
            aria-label='close'
            size="large">
            <Close />
          </IconButton>
        </Tooltip>
        <Typography variant='h6' style={{ textAlign: 'center', flexGrow: 1 }}>
          {supporticleUpdate?.articleTitle || 'New Support Article'}
        </Typography>

        {isForCreate &&
          <Tooltip title='Save' placement='bottom'>
            <IconButton
              className='qa-button-supporticle-isForCreate-save'
              color='inherit'
              size='small'
              onClick={() => handleCreate()}
            >
              {!createLoading && <Save color='inherit' />}
              {createLoading && <CircularProgress size={30} color='secondary' />}
            </IconButton>
          </Tooltip>}

        {!isForCreate &&
          <>
            <Tooltip title='Delete' placement='bottom'>
              <IconButton
                className='qa-button-supporticle-delete'
                color='inherit'
                edge='end'
                size='small'
                style={{ marginRight: '0.5em' }}
                onClick={() => setShowDeleteDialog(true)}
              >
                {!deleteLoading && !showDeleteDialog && <Delete color='inherit' />}
                {deleteLoading && <CircularProgress size={30} color='secondary' />}
              </IconButton>
            </Tooltip>
            <Tooltip title='Save' placement='bottom'>
              <IconButton
                className='qa-button-supporticle-isNotForCreate-save'
                color='inherit'
                size='small'
                onClick={handleUpdate}
              >
                {!updateLoading && <Save color='inherit' />}
                {updateLoading && <CircularProgress size={30} color='secondary' />}
              </IconButton>
            </Tooltip>
          </>}

      </Toolbar>
    </AppBar>

    {/* throw up an 'are you sure' dialog before deleting */}
    <Dialog
      className='qa-dialog-supporticle-delete'
      open={showDeleteDialog}
      onClose={() => setShowDeleteDialog(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Are you sure you want to permanently delete this Support Article?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className='qa-button-supporticle-delete-dialog-go-back'
          onClick={() => setShowDeleteDialog(false)}
          color='primary'
        >
          Go Back
        </Button>
        <Button
          className='qa-button-supporticle-delete-dialog-delete'
          onClick={handleDelete}
          color='secondary'
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>

    {/* throw up an 'are you sure' dialog before closing if there are unsaved changes */}
    <Dialog
      className='qa-dialog-supporticle-unsaved-changes'
      open={showSaveDialog}
      onClose={() => setShowSaveDialog(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          You have unsaved changes. Close without saving?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className='qa-button-supporticle-unsaved-changes-dialog-go-back'
          onClick={() => setShowSaveDialog(false)}
          color='primary'
          aria-label='Go Back'
        >
          Go Back
        </Button>
        <Button
          className='qa-button-supporticle-unsaved-changes-dialog-close-without-saving'
          onClick={onShowSupporticleEditClose}
          color='secondary'
          autoFocus
        >
          Close without saving
        </Button>
      </DialogActions>
    </Dialog>

    {/* throw up a dialog if we get a mutation error */}
    <Dialog
      open={showErrorDialog}
      onClose={() => setShowErrorDialog(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {errorDialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setShowErrorDialog(false)}
          color='primary'
          aria-label='Go Back'
        >
          Go Back
        </Button>
      </DialogActions>
    </Dialog>

    <Container style={{ marginTop: '5em' }}>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={4}>
          <TextField
            className='qa-textField-supporticle-term'
            id='term'
            label='Term'
            name='term'
            variant='filled'
            fullWidth
            value={supporticleUpdate.term}
            onChange={handleChange('term')}
            margin='dense'
            placeholder='Example: "Opportunity"'
          />
        </Grid>
        <Grid item xs>
          <TextField
            className='qa-textField-supporticle-definition'
            id='definition'
            label='Term Definition'
            name='definition'
            variant='filled'
            fullWidth
            value={supporticleUpdate.definition}
            onChange={handleChange('definition')}
            margin='dense'
            placeholder='Example: "An individual with whom we have been in contact regarding a campaign."'
          />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={4}>
          {!uniqueSlugLoading &&
            <TextField
              className='qa-textField-supporticle-slug'
              id='slug'
              label='Slug'
              name='slug'
              fullWidth
              variant='filled'
              required
              value={stringSlugValue}
              onChange={e => setStringSlugValue(e.target.value)}
              margin='dense'
              placeholder='Example: "opportunity"'
            />}
          {uniqueSlugLoading && <><CircularProgress size={14} /><p>Verifying and formatting slug...</p></>}
        </Grid>
        <Grid item xs>
          <Autocomplete
            id='categories'
            fullWidth
            value={supporticleUpdate.categories}
            onChange={handleChange('categories')}
            margin='dense'
            freeSolo
            options={selectableCategories.map(option => option.category) || []}
            renderInput={(params) => (
              <TextField
                {...params}
                className='qa-textField-supporticle-categories'
                label='Support Article Category'
                name='categories'
                variant='filled'
                onChange={handleChange('categories')}
                placeholder='Example: "Training"'
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Autocomplete
            id='subCategories'
            fullWidth
            value={supporticleUpdate.subCategories}
            onChange={handleChange('subCategories')}
            margin='dense'
            freeSolo
            disabled={!supporticleUpdate.categories.length}
            options={selectableCategories.find(option => option.category === supporticleUpdate.categories[0] || option.category === supporticleUpdate.categories)?.subCategories || []}
            renderInput={(params) => (
              <TextField
                {...params}
                className='qa-textField-supporticle-subCategories'
                label='Support Article SubCategories'
                name='subCategories'
                onChange={handleChange('subCategories')}
                variant='filled'
                placeholder='SubCategories separated by commas, example: "opportunities, opportunity, intake"'
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs={4}>
          <TextField
            className='qa-textField-supporticle-author'
            id='author'
            label='Author'
            name='author'
            fullWidth
            variant='filled'
            autoComplete='name'
            value={supporticleUpdate.author}
            onChange={handleChange('author')}
            margin='dense'
            placeholder='Example: "Ted Striker"'
          />
        </Grid>
        <Grid item xs>
          <TextField
            select
            fullWidth
            name='userPermissions'
            id='userPermissions'
            variant='filled'
            label='Users With View Permissions'
            SelectProps={{
              multiple: true,
              value: supporticleUpdate.userPermissions,
              onChange: handleChange('userPermissions')
            }}
          >
            {supportPermissions.map(sr => (
              <MenuItem
                key={sr.value}
                value={sr.value}
              >
                {sr.displayName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        <Grid item xs>
          <TextField
            className='qa-textField-supporticle-title'
            id='articleTitle'
            label='Support Article Title'
            name='articleTitle'
            fullWidth
            variant='filled'
            value={supporticleUpdate.articleTitle}
            onChange={handleChange('articleTitle')}
            margin='dense'
            placeholder='Example: "What is a CaseOpp Opportunity?"'
          />

        </Grid>
      </Grid>
      {!supporticleUpdate.attachmentForContent &&
      <Grid
        container
        spacing={1}
        direction='column'
        justifyContent='space-between'
        alignItems='left'
      >
        <Grid item xs>
          <Fab
            color='primary'
            onClick={() => setAttachmentDialogOpen(true)}
          >
            <AttachFile />
          </Fab>
          {/* </Grid>
        <Grid item xs> */}
          {attachments.map(currentAttachment => {
            return (
              <Chip
                key={currentAttachment.link || currentAttachment.path}
                label={currentAttachment.fileName || currentAttachment.name}
                sx={{ verticalAlign: 'top' }}
                onDelete={() => setAttachments(attachments.filter(attachment => attachment.link !== currentAttachment.link || attachment.path !== currentAttachment.path))}
              />
            )
          })}
        </Grid>
      </Grid>}
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        style={{ marginTop: '1em' }}
      >
        <Grid item xs>
          <ReactQuill
            modules={quillModules}
            formats={quillFormats}
            id='articleText'
            className='qa-textField-supporticle-text'
            label='Support Article Text'
            theme='snow'
            name='articleText'
            defaultValue={articleText}
            onChange={handleArticleTextChange}
            placeholder='Example: "A CaseOpp Opportunity represents an individual with whom we have been in contact regarding a campaign. An Opportunity is..."'
          />
        </Grid>
      </Grid>
    </Container>
    <DropzoneDialog
      fileObjects={attachments}
      open={attachmentDialogOpen}
      onDrop={newFileObjects => {
        setAttachments([].concat(attachments, newFileObjects))
      }}
      onDelete={deleteFileObject => setAttachments(attachments.filter(obj => obj !== deleteFileObject))}
      onSave={() => setAttachmentDialogOpen(false)}
      filesLimit={5}
      showPreviews
      maxFileSize={1000e+6} // 1GB in binary
      onClose={() => setAttachmentDialogOpen(false)}
      submitButtonText='Attach'
      initialFiles={attachments}
    />
  </>
}

export default SupporticleEdit
