import Typography from '@mui/material/Typography'
import { consoleATTN, consoleDev, clientWindow } from '../../src/helpers/helper' // eslint-disable-line no-unused-vars
import { getRedirect } from '../../src/helpers/localStorageHelper'
import { WrongAppVersionComponent } from '../../pages/wrongAppVersion'
import Link from 'next/link'

const redirect = getRedirect()

const thisFile = 'Welcome ' // eslint-disable-line no-unused-vars

export default function Welcome ({ host }) {
  // If we had trouble with app version, this will be set
  if (redirect) {
    return (<WrongAppVersionComponent {...{ redirect }} />)
  } else {
    return (
      <div data-testid='welcome' style={{ marginTop: host && host.substr(0, 4) === 'sta.' ? '4em' : '1em' }}>
        <Typography className='qa-login-welcome' variant='h4' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
          Welcome to CaseOpp!
        </Typography>
        <br />
        <Typography className='qa-login' variant='h5' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
          Please&nbsp;
          <Link href={`/api/auth/login?returnTo=${clientWindow.location.pathname}`}>
            <a data-testid='mainLogin' >login</a>
          </Link>
        </Typography>
        <br />
        <Typography className='qa-prohibited' variant='h6' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
          Unauthorized access prohibited.
        </Typography>
      </div>
    )
  }
}
