import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { green, red, blue } from '@mui/material/colors'
import { PureComponent } from 'react'
/* eslint-disable no-unused-vars */
import {
  setThemeModeRaw, toFlatPropertyMap, consoleDev, isValidThemeMode, clientWindow
} from './helpers/helper'

const thisFile = 'src/theme.js '
/* eslint-enable no-unused-vars */

// A theme with custom primary and secondary color.
const themeBuilder = (themeType = 'light') => {
  if (!isValidThemeMode(themeType)) themeType = 'light'
  const themeRaw = {
    palette: {
      green,
      red,
      blue,
      type: themeType,
      primary: {
        main: '#50717b'
      },
      secondary: {
        main: '#8ecccc'
      },
      error: {
        main: '#ec1c24'
      },
      local: {
        light: '#afafaf',
        main: '#808080',
        dark: '#545454',
        contrastText: '#000000'
      },
      staging: {
        light: '#e74a4d',
        main: '#e11d21',
        dark: '#9d1417',
        contrastText: {
          light: '#000000',
          main: '#ffffee',
          dark: '#ffffff'
        }
      },
      production: {
        light: '#50717b',
        main: '#50717b',
        dark: '#747474',
        contrastText: '#000000'
      }
    },
    typography: {
      useNextVariants: true
    },
    overrides: {
      MuiListItem: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(0,0,0,0.4)'
          }
        }
      },
      MuiSelect: {
        select: {
          minWidth: '120px'
        }
      }
    }
  }

  // Will loop through all props looking for any that end in modeList[*] (e.g. 'light', 'dark').  If it finds one, and it is === theme.palette.mode,
  // it will set the peer main value to [palette.mode] value.  When done main will be equal to the mode.
  const themeMode = setThemeModeRaw(themeRaw)

  return createTheme(themeMode)
}
export default themeBuilder
/**
 * Uses buildTheme prop to know that palette mode had been processed.  Otherwise themeBuilder() will be called with default variables if none provided.
 *
 * @export
 * @class CustomThemeProvider
 * @extends {PureComponent}
 * @props {builtTheme, themeMode}
 */
export class CustomThemeProvider extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      theme: props.builtTheme || themeBuilder(props.themeMode)
    }
    this.stylesheet = clientWindow?.document.createElement('style')
    clientWindow?.document.head.prepend(this.stylesheet)
  }

  render () {
    const { theme } = this.state

    if (process.env.CSS_GLOBAL_VARIABLES) {
      const flatTheme = toFlatPropertyMap(theme)
      // Collect non function props.
      let cssVariables = String()
      for (const [key, value] of Object.entries(flatTheme)) {
        if (typeof value === 'function') continue
        if (!(key && key.replaceAll)) continue
        // And build string literal
        cssVariables += `--theme-${key.replaceAll('.', '-')}: ${value};` + '\n'
      }

      if (clientWindow) {
        clientWindow.document.body.className = `theme-${theme.palette.mode}`
        this.stylesheet.innerHTML = `
        :root {
          ${cssVariables}
        }
      `
      }
    }

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}
