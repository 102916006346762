import { useApolloClient } from '@apollo/client'
// MUI Core
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import NativeSelect from '@mui/material/NativeSelect'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Close, Warning } from '@mui/icons-material'
import { Autocomplete } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'reactn'
import { gqlErrorMessages } from '../../graphql/client'
import {
  createNotificationMutation,
  updateNotificationMutation
} from '../../src/gql/notificationGQL'
import notificationStyles from '../../src/styles/notifications.module.css'

const thisFile = 'components/Notifications/CreateEditNotificationDialog.js ' // eslint-disable-line no-unused-vars

const CreateEditNotificationDialog = (props) => {
  const {
    cellClass = '',
    createEditNotificationHook,
    fsdNotification = false,
    getRecipientName = () => { },
    myNotification = false,
    queryNotifications = () => { },
    user,
    users,
    toggleModal
  } = props

  const gqlClientRef = useRef(useApolloClient())
  const [createEditNotification, setCreateEditNotification] = createEditNotificationHook
  const prevRecipientGroup = createEditNotification?.recipientGroup || false
  const notificationMessageRef = useRef(createEditNotification.message)
  const notificationLinkRef = useRef(createEditNotification.link)
  notificationMessageRef.current = createEditNotification.message
  notificationLinkRef.current = createEditNotification.link
  let userRoles = []
  /* ----------userRoles---------- */
  if (!fsdNotification && !myNotification) {
    // get all user.roles arrays and flatten
    const arrayOfUserRoleArrays = users.map(user => user?.roles).flat()
    // pull out just the role names
    const roleNamesWithDupes = arrayOfUserRoleArrays.map(role => role?.name)
    // remove dupes
    const userRolesUnsorted = [...new Set(roleNamesWithDupes)]
    // remove undefined and sort
    userRoles = userRolesUnsorted.filter(role => role).sort()
  }
  /* ----------userRoles---------- */
  const [createEditError, setCreateEditError] = useState(false)
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false)
  const [recipientChange, setRecipientChange] = useState(false)

  /**
   * runs a mutation to create a notification
   * on success it wipes the dialog state and re-queries the notifications
   */
  const createNotification = async () => {
    const createNotificationsResult = await gqlClientRef.current.mutate({
      mutation: createNotificationMutation,
      variables: {
        input: {
          message: createEditNotification.message,
          priority: createEditNotification.priority,
          recipientGroup: createEditNotification.recipientGroup,
          recipients: createEditNotification.recipients,
          link: createEditNotification.link,
          postDate: createEditNotification.postDate ? new Date(createEditNotification.postDate) : new Date()
        }
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'createNotification error:', gqlErrorMessages(error))
    })
    if (!createNotificationsResult.data.createNotification.value) {
      setCreateEditError('Create')
      // throw new Error(thisFile, 'createNotifications mutation did not save')
    } else {
      setCreateEditNotification()
      queryNotifications()
    }
  }

  /**
   * runs a mutation to update an existing notification
   * on success it wipes the dialog state and re-queries the notifications
   */
  const editNotification = async () => {
    const editNotificationsResult = await gqlClientRef.current.mutate({
      mutation: updateNotificationMutation,
      variables: {
        input: {
          id: createEditNotification.id,
          priority: createEditNotification.priority,
          recipientGroup: createEditNotification.recipientGroup,
          recipients: createEditNotification.recipients,
          message: createEditNotification.message,
          link: createEditNotification.link,
          postDate: createEditNotification.postDate ? new Date(createEditNotification.postDate) : new Date()
        }
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'editNotificationsResult error:', gqlErrorMessages(error))
    })
    if (!editNotificationsResult.data.updateNotification.value) {
      setCreateEditError('Edit')
      // throw new Error(thisFile, 'editNotifications mutation did not save')
    } else {
      setCreateEditNotification()
      queryNotifications()
    }
  }

  /**
   * checks to see if the required fields are filled in
   * - priority
   * - recipient group
   * - recipients user id array
   * - message ref has values
   */
  useEffect(() => {
    if (createEditNotification.priority && createEditNotification.recipientGroup && createEditNotification.recipients?.length && notificationMessageRef.current && notificationMessageRef.current !== null && notificationMessageRef.current !== '') {
      setRequiredFieldsFilled(true) // all fields filled in
    } else {
      setRequiredFieldsFilled(false) // some fields missing
    }
  }, [createEditNotification, notificationMessageRef])

  const getPostDate = () => {
    // createEditNotification.postDate || new Date()
    // if the date is in the past update it
    if (createEditNotification.postDate && new Date(createEditNotification.postDate) < new Date()) {
      return new Date()
    } else {
      return createEditNotification.postDate || new Date()
    }
  }

  const getPostDateDiff = () => {
    if (createEditNotification.postDate) {
      const diffInMil = new Date(createEditNotification.postDate) - new Date()
      if (diffInMil > 59999) { // if more than a minute in the future
        const days = Math.floor(diffInMil / 8.64e+7)
        const hours = Math.floor((diffInMil - (days * 8.64e+7)) / 3.6e+6)
        const minutes = Math.floor(((diffInMil - (days * 8.64e+7)) - (hours * 3.6e+6)) / 60000)
        let returnPostDateDiff = ''
        if (days > 0) returnPostDateDiff += `${days} days`
        if (days > 0 && hours > 0) returnPostDateDiff += ' - '
        if (hours > 0) returnPostDateDiff += `${hours} hours`
        if (hours > 0 && minutes > 0) returnPostDateDiff += ' - '
        if (days > 0 && hours === 0 && minutes > 0) returnPostDateDiff += ' - '

        if (minutes > 0) returnPostDateDiff += `${minutes} minutes`
        if (days > 0 || hours > 0 || minutes > 0) returnPostDateDiff += ' from now'
        return returnPostDateDiff// `${days} days - ${hours} hours - ${minutes} minutes from now`}
      }
    }
  }

  const recipientsGridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true
    },
    {
      field: 'userId',
      headerName: 'Recipient',
      flex: 0.7,
      valueGetter: (params) => (getRecipientName(params.value))
    },
    {
      field: 'acknowledged',
      headerName: 'Acknowledged',
      flex: 0.8,
      cellClassName: (params) => (params.value ? notificationStyles.acknowledged : notificationStyles.pending)
    },
    {
      field: 'snooze',
      headerName: 'Snoozed',
      flex: 1,
      type: 'dateTime',
      headerClassName: () => (notificationStyles.snoozed),
      valueGetter: (params) => {
        return (new Date(params.value))
      },
      cellClassName: (params) => ((new Date(params.value) > new Date()) ? notificationStyles.snoozed : null)
    }
  ]

  return <>
    <Dialog
      open={!!(createEditNotification.id) || createEditNotification.id === 'Create'}
      onClose={() => setCreateEditNotification()}
      disableEnforceFocus
    >
      <DialogTitle className={!fsdNotification ? cellClass : notificationStyles.priority_2} />
      <DialogContent
        style={{ backgroundColor: 'unset' }}
      >
        <Grid
          container
          spacing={2}
        >
          {!fsdNotification &&
            <>
              <Grid item xs={2}>Priority:</Grid>
              <Grid item xs={10}>
                <NativeSelect
                  defaultValue={createEditNotification.priority || ''}
                  onChange={(event) => {
                    setCreateEditNotification(prev => ({ ...prev, priority: +event.target.value })) // + (Unary Operator) Tries to convert the operand into a number i.e. +"123" === 123
                  }}

                >
                  <option value='' disabled />
                  <option className={notificationStyles.priority_1} value={1}>1 High-immediate action required</option>
                  <option className={notificationStyles.priority_2} value={2}>2 Medium</option>
                  <option className={notificationStyles.priority_3} value={3}>3 Low</option>
                  <option className={notificationStyles.priority_4} value={4}>4 None</option>
                </NativeSelect>
              </Grid>
            </>}
          {!fsdNotification && !myNotification &&
            <>
              <Grid item xs={2}>Recipient:</Grid>
              <Grid item xs={10}>
                <NativeSelect
                  multiple
                  defaultValue={createEditNotification.recipientGroup || ''}
                  onChange={(event) => {
                    if (prevRecipientGroup && prevRecipientGroup !== event.target.value) {
                      setRecipientChange(true)
                    }
                    setCreateEditNotification(prev => ({ ...prev, recipientGroup: event.target.value }))
                    // set recipients array based on group selected
                    const usersByGroup = []
                    for (const user of users) {
                      // dynamic list of roles rather than entire list
                      // so that non-admitted roles don't show in list
                      const usersRoles = user.roles?.map(role => role.name)
                      if (event.target.value === 'all') {
                        usersByGroup.push({ userId: user.user_id, acknowledged: false })
                        continue // this iteration is done go to the next one
                      }
                      if (event.target.value === 'allInternal') {
                        if (user.user_id.indexOf('@caseopp.com') > -1 ||
                        user.user_id.indexOf('@reciprocityindustries.com') > -1 ||
                        user.email.indexOf('@test.com') > -1 ||
                        user.email.indexOf('puppetmaster') > -1) {
                          usersByGroup.push({ userId: user.user_id, acknowledged: false })
                        }
                        continue // this iteration is done go to the next one
                      }
                      if (event.target.value === 'individual') {
                        setCreateEditNotification(prev => ({ ...prev, recipients: [] }))
                        continue
                      }
                      if (usersRoles?.includes(event.target.value)) {
                        usersByGroup.push({ userId: user.user_id, acknowledged: false })
                      }
                    }
                    if (event.target.value !== 'individual') setCreateEditNotification(prev => ({ ...prev, recipients: usersByGroup }))
                  }}

                >
                  <option value='' disabled />
                  <option value='@mention' disabled>@mention</option>
                  <option value='all'>all</option>
                  <option value='allInternal'>all internal</option>
                  <option value='individual'>individual(s)</option>
                  {userRoles.map((role, roleIndex) => {
                    return (<option key={roleIndex} value={role}>{role}</option>)
                  })}
                </NativeSelect>
              </Grid>
            </>}
          {createEditNotification.recipientGroup === 'individual' && !myNotification &&
            <>
              <Grid item xs={2}>
                User Name:
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  id='select-users'
                  label='Users'
                  clearOnBlur={false}
                  limitTags={4}
                  value={createEditNotification?.recipients || []}
                  isOptionEqualToValue={(option, value) => {
                    return value.userId === option?.user_id
                  }}
                  getOptionLabel={(option) => {
                    let optionLabel = option?.name + ' - ' + option?.email
                    if (option?.name === option?.email) optionLabel = option?.name
                    return optionLabel
                  }}
                  multiple
                  disableCloseOnSelect
                  options={
                    users.filter(user => (!user.blocked && (user.user_id.indexOf('@caseopp.com') > -1 ||
                      user.user_id.indexOf('@reciprocityindustries.com') > -1 ||
                      user.email.indexOf('@test.com') > -1 ||
                      user.email.indexOf('puppetmaster') > -1)))
                      .sort((a, b) => {
                        // makes sure the users have roles before trying to compare
                        if (a.roles && b.roles) {
                          return (a.roles[0].name.localeCompare(b.roles[0].name))
                        } else {
                          return 0
                        }
                      })
                  }
                  groupBy={(option) => {
                    return option?.roles?.length ? option?.roles[0]?.name : 'No Role'
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        color='secondary'
                        label={option?.name || users.find(user => user.user_id === option.userId)?.name}
                        {...getTagProps({ index })}
                      />
                    ))}
                  onChange={(event, newValue) => {
                    const usersToNotify = newValue?.map(user => {
                      if (user?.user_id) return { id: user.id, userId: user.user_id, acknowledged: false, done: false } // if adding user to notification
                      return user // if user already existed on notification
                    })
                    setCreateEditNotification(prev => ({ ...prev, recipients: usersToNotify }))
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Users'
                    />
                  )}
                />
              </Grid>
            </>}
          <Grid item xs={12} style={{ backgroundColor: 'lightgray', marginTop: '15px' }}>
            <TextField
              style={{ width: '100%' }}
              multiline
              rows={6}
              defaultValue={createEditNotification.message}
              inputRef={notificationMessageRef}
              onBlur={(event) => (setCreateEditNotification(prev => ({ ...prev, message: event.target.value })))}
              onChange={(event) => {
                notificationMessageRef.current = event.target.value
              }}
            />
          </Grid>
          <Grid item xs={2}>
            Post Date:
          </Grid>
          <Grid item xs={10}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                id='post-date'
                value={getPostDate()}
                onChange={(e) => {
                  setCreateEditNotification(prev => ({ ...prev, postDate: e }))
                }}
                showTodayButton
                disablePast
              />
            </LocalizationProvider>
            {createEditNotification.postDate && <Typography variant='caption'>{getPostDateDiff()}</Typography>}
          </Grid>
          <Grid item xs={2}>
            Link:
          </Grid>
          <Grid item xs={10}>
            {!fsdNotification &&
              <TextField
                style={{ width: '100%' }}
                placeholder='Optional'
                defaultValue={createEditNotification.link}
                inputRef={notificationLinkRef}
                onBlur={(event) => (setCreateEditNotification(prev => ({ ...prev, link: event.target.value })))}
                onChange={(event) => {
                  notificationLinkRef.current = event.target.value
                }}
              />}
            {fsdNotification &&
              <Typography>{createEditNotification.link}</Typography>}
          </Grid>
          {!recipientChange && createEditNotification?.id !== 'Create' &&
            <Grid item xs={12}>
              <div style={{ height: 'auto', width: '100%' }}>
                <DataGridPremium
                  columns={recipientsGridColumns}
                  rows={createEditNotification.recipients}
                  rowHeight={38}
                  disableMultipleSelection
                  disableSelectionOnClick
                  // components={{
                  //   Toolbar: GridToolbar // hidden pending z-index fix from material ui for menus in modals
                  // }}
                  autoHeight
                  density='compact'
                  sortModel={[
                    {
                      field: 'acknowledged',
                      sort: 'asc'
                    },
                    {
                      field: 'userId',
                      sort: 'asc'
                    }
                  ]}
                  componentsProps={{
                    filterPanel: {
                      columnsSort: 'asc'
                    }
                  }}
                />
              </div>
            </Grid>}
        </Grid>
      </DialogContent>
      <DialogActions>
        {!(requiredFieldsFilled) &&
      'There are missing fields'}
        <Button
          color='primary'
          onClick={() => toggleModal(false)}
        >
          Cancel
        </Button>
        {user && user.permissions.includes('create:notifications') && createEditNotification?.id === 'Create' &&
          <Button
            color='primary'
            disabled={!(requiredFieldsFilled)}
            onClick={() => {
              createNotification(createEditNotification)
            }}
          >
            Save
          </Button>}
        {user && user.permissions.includes('update:notifications') && createEditNotification.id !== 'Create' &&
          <Button
            color='primary'
            disabled={!(requiredFieldsFilled)}
            onClick={() => {
              editNotification(createEditNotification)
            }}
          >
            Update
          </Button>}
      </DialogActions>
    </Dialog>
    {!!(createEditError) &&
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={!!(createEditError)}
        autoHideDuration={6000}
        onClose={() => {
          setCreateEditError(false)
        }}
      >
        <SnackbarContent
          className={notificationStyles.warning}
          aria-describedby='message-id'
          message={
            <span id='message-id' className={notificationStyles.message}>
              <Warning className={classNames(notificationStyles.icon, notificationStyles.iconVariant)} />
              {createEditError + ' Notification Failed'}
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={() => {
                setCreateEditError(false)
              }}
              size="large">
              <Close className={classNames(notificationStyles.icon, notificationStyles.iconVariant)} />
            </IconButton>
          ]}
        />
      </Snackbar>}

  </>
}

export default CreateEditNotificationDialog
