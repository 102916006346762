/* global fetch */ // nextjs polyfills
import { useEffect, useRef, useState } from 'react'
import { consoleDev, consoleATTN, consoleError, consoleWarn, consoleInfo, baseUrl, hasUserRole, getApiUrl } from '../helpers/helper' // eslint-disable-line no-unused-vars
import { setRedirect } from '../../src/helpers/localStorageHelper'
import packageFile from '../../package.json'
const { version: appVersion } = packageFile

const clientWindow = (typeof window !== 'undefined') ? window : undefined
const thisFile = 'src/auth/user ' // eslint-disable-line no-unused-vars

export async function fetchUser (reqHeaders) {
  const { cookie = '', signal = undefined } = reqHeaders
  if (clientWindow && clientWindow.__user) {
    return clientWindow.__user
  }

  let res
  try {
    // const apiUrl = await getApiUrl()
    // const url = `${apiUrl}/user?fullUser=1&_vercel_no_cache=1`
    // console.info('fallback url:', `${apiUrl}/user?fullUser=1&_vercel_no_cache=1`)
    const url = `${baseUrl || String()}/api/user?fullUser=1&_vercel_no_cache=1`
    const options = {
      cache: 'no-cache',
      headers: {
        cookie,
        credentials: 'include', // include, *same-origin, omit
        appVersion
      },
      signal
    }

    res = await fetch(
      url, options
    ).catch(err => {
      throw new Error(consoleError(thisFile, 'fetchUser inner Error:', err))
    })
  } catch (error) {
    throw new Error(consoleError(thisFile, 'fetchUser outer Error:', error))
  }

  if (!res?.ok) {
    // Look for our own return json if present.  Wrap in try to ensure no uncaught errors if not.
    let resJson
    try {
      resJson = await res.json()
    } catch (error) {
      consoleError(thisFile, error.message)
    }
    // No extra information by default
    if (resJson?.action && process.env.RI_DEBUG) consoleError('ERROR:', resJson.error, `\n  LOG:\n\t${resJson.actionLog.join('\n\t')}`)
    if (clientWindow) {
      if (clientWindow.__user) delete clientWindow.__user
      // This will write to local storage a redirect prop that the Welcome component will use to show alternate
      // component
      if (resJson?.returnTo) setRedirect(resJson.returnTo)
    }
    return undefined
  }

  let user
  try {
    user = await res.json()
    if (clientWindow) {
      clientWindow.__user = user
    }
  } catch {
    return undefined
  }
  return user
}

export function useFetchUser ({ autoPromptLogin } = {}) {
  // const loading = useLoading()

  const autoPromptLoginRef = useRef(autoPromptLogin)
  // undefined means not checked.  null means checked and not logged in.
  const [userState, setUserState] = useState((clientWindow && clientWindow.__user) || undefined)

  // SET USER
  useEffect(() => {
    const { current: autoPromptLogin } = autoPromptLoginRef
    let isMounted = true
    const abortController = new AbortController();/* global AbortController */

    (async () => {
      try {
        await fetchUser({ signal: abortController.signal }).then(user => {
          // Only set the user if the component is still mounted
          if (isMounted) {
            // When the user is not logged in but login is required
            if (autoPromptLogin && !user) {
              consoleWarn(thisFile, 'useFetchUser->fetchUser.then() autoPromptLogin')
              if (clientWindow) clientWindow.location.pathname = 'api/auth/login'
              return
            }
            if (user) {
              setUserState(user)
              const allowedFirmLocations = ['/cases', '/support', '/myProfile']
              if (user?.roles?.includes('firmAdmin')) {
                allowedFirmLocations.push('/campaignStats')
                allowedFirmLocations.push('/partners')
              }
              let locationAuthorized = true
              const isFirmUser = (user?.roles?.includes('firm') || user?.roles?.includes('firmAdmin'))
              if (isFirmUser) locationAuthorized = false
              for (const location of allowedFirmLocations) {
                if (clientWindow && clientWindow.location?.pathname.indexOf(location) > -1) locationAuthorized = true
              }
              if (isFirmUser && !locationAuthorized) {
                consoleWarn(thisFile, 'Detected Firm user, redirecting to cases. Path: ', clientWindow.location.pathname)
                clientWindow.location.href = '/cases'
              }
              if (user.app_metadata?.homePage && clientWindow.location.pathname === '/') { // only redirect if user is going to home page
                clientWindow.location.href = user.app_metadata?.homePage
              }
              // DEV NOTE: if you get an error here, you may be trying to render an empty component file
            } else {
              setUserState(null)
            }
          } else {
            consoleWarn(thisFile, 'useFetchUser->fetchUser.then() NOT mounted')
          }
          // setUserLoading(false)// On all exits
        }).catch(error => {
          consoleError(thisFile, 'useEffect fetchUser() inner Error:', error.message)
          setUserState(null)
          // setUserLoading(false)// On all exits
        })
      } catch (error) {
        consoleError(thisFile, 'useEffect fetchUser() outer Error:', error.message)
        setUserState(null)
        // setUserLoading(false)// On all exits
      }
    })()
    return () => {
      // setUserLoading(false)// On all exits
      abortController.abort()
      isMounted = false
    }
  }, [])

  return userState
}
