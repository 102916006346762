import gql from 'graphql-tag'

export const multipleSupporticlesQuery = gql`
query supporticlesSearch($input: SupporticlesSearchInput!){
  supporticlesSearch(input: $input){
    documentCount
    data{
      slug
      term
      definition
      articleTitle
      articleText
      categories
      subCategories
      author
      createdAt
      updatedAt
      userPermissions
      attachmentsForContent {
        link
        fileName
        contentType
        fileSize
      }
    }
  }
}`

export const singleSupporticleQuery = gql`
query supporticle($slug: String!) {
  supporticle(slug: $slug){
    slug
    term
    definition
    articleTitle
    articleText
    categories
    subCategories
    author
    createdAt
    updatedAt
    userPermissions
    attachmentsForContent {
      link
      fileName
      contentType
      fileSize
    }
  }
}`

export const createSupporticleMutation = gql`
  mutation createSupporticle($input: SupporticleCreateInput!){
    createSupporticle(input: $input){
      slug
      term
      definition
      articleTitle
      articleText
      categories
      userPermissions
      author
      createdAt
      updatedAt
      attachmentsForContent {
        link
        fileName
        contentType
        fileSize
      }
    }
  }`

export const updateSupporticleMutation = gql`
  mutation updateSupporticle($input: SupporticleUpdateInput!){
    updateSupporticle(input: $input){
      slug
      term
      definition
      articleTitle
      articleText
      categories
      subCategories
      userPermissions
      author
      createdAt
      updatedAt
      attachmentsForContent {
        link
        fileName
        contentType
        fileSize
      }
    }
  }`

export const deleteSupporticleMutation = gql`
  mutation deleteSupporticle($slug: String!) {
    deleteSupporticle(slug: $slug)
  }`

export const uniqueSupporticleSlugQuery = gql`
  query uniqueSupporticleSlug($slug: String!){
    uniqueSupporticleSlug(slug: $slug){
      slug
    }
  }`

export const quillModules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'super' }, { script: 'sub' }],
    [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link', 'image', 'video', 'formula'],
    ['clean']
  ]
}

export const quillFormats = [
  'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
  'list', 'bullet', 'indent', 'script', 'background', 'color', 'size', 'font',
  'align', 'link', 'image'
]
