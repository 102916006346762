import App from '../components/App/App'
import useRIUser from '../src/hooks/riUser'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import wrongAppVersionStyles from './styles/wrongAppVersion.module.css'
import { useState, useEffect } from 'reactn'
import { clientWindow } from '../src/helpers/helper'

const thisFile = 'pages/wrongAppVersion ' // eslint-disable-line no-unused-vars

const WrongAppVersionPage = () => {
  const { user, accessRules } = useRIUser()

  return (
    <App user={user} location={false} accessRules={accessRules} headerBoard={<h5>oops</h5>}>
      <WrongAppVersionComponent />
    </App>
  )
}
export const WrongAppVersionComponent = (props) => {
  const [counter, setCounter] = useState(process.env.VERSION_ERROR_RELOAD_SECONDS)
  const { redirect } = props
  const disabled = redirect && redirect.includes('disabled')

  const reload = () => {
    clientWindow?.location.replace('/')
  }

  useEffect(() => {
    if (counter) {
      setTimeout(() => {
        setCounter(counter - 1)
      }, 1000)
    } else {
      reload()
    }
  }, [counter])

  return (
    <Paper className={wrongAppVersionStyles.paper}>
      <Typography
        variant='h3'
        color='error'
      >
        {disabled ? 'APP DISABLED' : 'There is a new version of CaseOpp, please reload to update your version'}
      </Typography>
      <Button className={wrongAppVersionStyles.reloadButton} onClick={() => reload()}>Reload Now ... (Reloading in: {counter} seconds.)</Button>
    </Paper>
  )
}

export default WrongAppVersionPage
