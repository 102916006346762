import gql from 'graphql-tag'

export const bugQuery = gql`
  query bugs {
    bugs {
      id
      title
      userId
      type
      votes
      description
      steps
      explain
      expected
      observed
      url
      status {
        id
        label
        slug
        color
      }
      jiraIssue
      loomLink
    }
  }
`
export const newBugMutation = gql`
  mutation createBug($input: BugInput!){
    createBug(input: $input) {
      type
      id
      title
      description
      explain
      steps
      expected
      observed
      url
      status {
        id
        label
        slug
        color
      }
      userId
      jiraIssue
      loomLink
    }
  }
`
export const updateBugMutation = gql`
  mutation updateBug($input: BugUpdateInput!){
    updateBug(input: $input) {
      id
      type
      title
      description
      explain
      steps
      expected
      observed
      url
      votes
      status {
        id
        label
        slug
        color
      }
      userId
      jiraIssue
      loomLink
    }
  }
`

export const deleteBugMutation = gql`
  mutation removeBug($input: String!) {
    removeBug(id: $input) {
      id
      title
    }
  }
`

module.exports = {
  bugQuery,
  newBugMutation,
  updateBugMutation,
  deleteBugMutation
}
