import gql from 'graphql-tag'

const priorityNewLeadsQuery = gql`
  query getPriorityNewLeads ($swr: Boolean) {
    opportunitiesPriorityNew(swr: $swr) {
      displayId
      caseManager
    }
  }`

module.exports = {
  priorityNewLeadsQuery
}
