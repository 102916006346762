import gql from 'graphql-tag'

export const gcsDocsQuery = gql`
  query gCSDocs($input: gCSDocsInput){
    gCSDocs(input: $input) {
      name
      updated
      contentType
      size
    }
  }`

export const gCSUploadFilesMutation = gql`mutation gCSUploadFiles($input: [gCSDocFileInput!]){
  gCSUploadFiles(input: $input) {
    reason
  }
}`

export const gCSUploadFileLinksQuery = gql`query gCSDocUploadLinks($input: [fileNameAndContentType!]){
  gCSDocUploadLinks(input: $input)
}
`

export const gCSDeleteDocumentMutation = gql`mutation gCSDeleteDoc($input: gCSDocDeleteInput!){
  gCSDeleteDoc(input: $input) {
    result
  }
}`

export const gCSDocDownloadLinkQuery = gql`
  query gCSDocDownloadLink($input: gCSDocDownloadLinkInput){
    gCSDocDownloadLink(input: $input)
  }`

export const gCSAttachmentUploadFilesQuery = gql`query gCSAttachmentUploadLinks($input: [attachmentNameAndContentType!]){
  gCSAttachmentUploadLinks(input: $input) {
    link
    fileName 
    contentType
    fileSize
  }
}
`

module.exports = {
  gcsDocsQuery,
  gCSUploadFilesMutation,
  gCSUploadFileLinksQuery,
  gCSDeleteDocumentMutation,
  gCSDocDownloadLinkQuery,
  gCSAttachmentUploadFilesQuery
}
