/* eslint-disable @next/next/no-img-element */
/* eslint-disable camelcase */
import { useApolloClient, useQuery } from '@apollo/client'
// MUI Core
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import AccountCircle from '@mui/icons-material/AccountCircle'
import BugReport from '@mui/icons-material/BugReport'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationImportant from '@mui/icons-material/NotificationImportant'
import Notifications from '@mui/icons-material/Notifications'
import NotificationsOff from '@mui/icons-material/NotificationsOff'
import PersonOutline from '@mui/icons-material/PersonOutline'
import PriorityHigh from '@mui/icons-material/PriorityHigh'
import MessageIcon from '@mui/icons-material/Message'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { setGlobal, useEffect, useGlobal, useState } from 'reactn'
import useSWR from 'swr'
import GlobalSearch from '../../components/Common/GlobalSearch'
import { myNotificationsQuery } from '../../src/gql/notificationGQL'
import { priorityNewLeadsQuery } from '../../src/helpers/headerHelper'
/* eslint-disable no-unused-vars */
import {
  baseContext, clientWindow, consoleError, getVersion, hasAccess
  , scrubTypename, consoleATTN, consoleDev, baseUrl, getJsonWithErrorHandling
} from '../../src/helpers/helper'
/* eslint-enable no-unused-vars */
import { deleteLastFilter } from '../../src/helpers/localStorageHelper'
import useLoading from '../../src/hooks/loadingHook'
import BugReportDialog from '../Common/BugReportDialog'
import UserNotifications from '../Notifications/UserNotifications'
import AutoAssignUserQueue from '../Common/AutoAssignUserQueue'
import headerStyles from './styles/header.module.css'
import Support from './Support'

import { navigationMenu } from '../../src/dictionaries/dictionaries'

const thisFile = 'Header ' // eslint-disable-line no-unused-vars

function Header (props) {
  // Extract Props
  const { theme, user, accessRules, headerBoard } = props
  const loading = useLoading()
  // Setup Side Menu Drawer
  const [open, setOpen] = useState(false)
  const [menuItemSelected, setMenuItemSelected] = useState(null)
  const [anchorElement, setAnchorElement] = useState(null)
  const [priorityNewLeadsCount, setPriorityNewLeadsCount] = useState(0)
  const [, setLoadingMessage] = useGlobal('loadingMessage')
  const router = useRouter()
  const [priorityOneNotifications, setPriorityOneNotifications] = useState([])
  const [notifications, setNotifications] = useState([])
  const [unReadNotificationsCount, setUnReadNotificationsCount] = useState(0)
  const [notificationsAnchorElement, setNotificationsAnchorElement] = useState(null)
  const [userQueueAnchorElement, setUserQueueAnchorElement] = useState(null)
  const [communicationsCount, setCommunicationsCount] = useState(0)

  const showLocal = baseContext === 'dev'
  const showStaging = baseContext === 'sta'

  const client = useApolloClient()

  // Badges
  // swr variable here is for E2E devTool sniffing. When undefined (without RI_DEBUG) nextjs tree shaking will remove it.
  const leadsFetcher = async () => client.query({ query: priorityNewLeadsQuery, variables: { swr: process.env.RI_DEBUG ? true : undefined }, fetchPolicy: 'no-cache' })
  const { data: priorityNewLeadsData, error: priorityNewLeadsError, isValidating: priorityNewLeadsIsValidating } = useSWR(
    !user ? null : 'priorityNewLeads',
    leadsFetcher,
    {
      refreshInterval: process.env.SWR_OFF ? 3.6e+6 : 60000,
      dedupingInterval: 60000,
      revalidateOnFocus: false,
      errorRetryCount: 3,
      errorRetryInterval: process.env.NODE_ENV !== 'test' ? 60000 : 0,
      onError: (priorityNewLeadsError) => consoleError(thisFile, 'Priority New Leads Error: ', priorityNewLeadsError)
    }
  )

  // Gets the total count of unhandled communications
  const getUnhandledCommunicationsCount = async (url) => {
    const communicationsURL = new URL(url, baseUrl)
    const params = new URLSearchParams()
    params.append('request', 'unhandledCommunicationsCount')
    const commCountResponse = await fetch(communicationsURL + `?${params}`, {
      method: 'GET'
    })
    const response = await commCountResponse.text()
    const commCount = await getJsonWithErrorHandling(response)
    if (commCount) {
      setCommunicationsCount(commCount)
    }
  }

  // Handles the auto refresh of the communications
  const {
    isValidating: communicationsLoading // eslint-disable-line no-unused-vars
  } = useSWR(
    'api/communications',
    getUnhandledCommunicationsCount,
    {
      refreshInterval: 60000, // Refresh every 60 seconds
      errorRetryCount: 3,
      onError: (getCommsError) => consoleError(thisFile, 'Get Communications count SWR error, details: ', getCommsError)
    }
  )

  /**
   * when called this function will query the users notifications
   * and set the notifications state value
   * IF there are priority 1 messages AND they are not acknowledged
   * - they will be stored on the priorityOneNotifications state value
   * - and then will load the first notification to be viewed immediately
   * - until there are no more to be acknowledged
   */
  const [pollInterval, setPollInterval] = useState(false)
  const [enablePolling, setEnablePolling] = useState(false)
  const { refetch: refetchNotifications } = useQuery(
    myNotificationsQuery,
    {
      enabled: enablePolling, // only run automatically if user is logged in
      variables: {
        input: { userId: user?.user_id }
      },
      ssr: false,
      pollInterval: pollInterval, // 15 seconds,
      notifyOnNetworkStatusChange: true, // update even if no diff in data
      onCompleted: (data) => {
        if (data?.myNotifications) {
          const myNotifications = scrubTypename(data.myNotifications)
          setNotifications(myNotifications)
          setUnReadNotificationsCount(myNotifications.filter(notification => (
            notification.recipients.find(recipient => recipient.userId === user.user_id).acknowledged === false &&
            // notification.recipients.find(recipient => recipient.userId === user.user_id).done === false &&
            new Date(notification.recipients.find(recipient => recipient.userId === user.user_id).snooze) <= new Date()
          ))?.length)
          // priority 1 notifications that ARE NOT acknowledged
          setPriorityOneNotifications(myNotifications.filter(notification => (notification.priority === 1 && notification.recipients.find(recipient => recipient.userId === user.user_id).acknowledged === false)))
          if (myNotifications.filter(notification => (notification.priority === 1 && notification.recipients.find(recipient => recipient.userId === user.user_id).acknowledged === false))?.length) {
            // if un-acknowledged priority one message "click" the notification icon to
            // open the notifications drawer
            const userMenu = document.getElementById('qa-userMenu')
            userMenu.click()
            const priorityButton = document.getElementById('notificationIconButton')
            priorityButton.click()
          }
        }
      },
      onError: (error) => {
        consoleError(thisFile, 'myNotificationsQuery error: ', error)
      }
    }
  )
  // set pollInterval to 15 seconds, and enablePolling to true if user.user_id is defined
  useEffect(() => {
    if (user?.user_id) {
      setPollInterval(15000)
      setEnablePolling(true)
    }
  }, [user?.user_id])

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMenuItemClick = (item, index) => {
    loading.loadingStackRemove('ALL')
    if (router.asPath !== item.route) {
      if (item.route.indexOf('http') > -1) { // Detect external url
        clientWindow && clientWindow.open(item.route, '_blank')
      }
      loading.loadingStackPush(item.load)
      setMenuItemSelected(index)
      setGlobal({ loadingMessage: 'Loading ' + item.label + '...' })
      setOpen(false)
    } else {
      handleDrawerClose()
    }
  }

  const handleUserMenu = event => {
    setAnchorElement(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setAnchorElement(null)
  }

  const handleUserMenuLogout = () => {
    setLoadingMessage('Unloading ...')
    loading.loadingStackPush('logout')
    router.push('/api/auth/logout')
  }

  const handleMyNewLeadsClick = () => {
    deleteLastFilter()
    setLoadingMessage('Loading My New Lead Opportunities ...')
    if (clientWindow && clientWindow.location.pathname === '/opportunities') {
      clientWindow.location.href = baseUrl + '/opportunities?sort=priority&sortDir=desc&status=newLead&caseManager=' + user.user_id
    }
    router.push({
      pathname: '/opportunities',
      query: {
        sort: 'priority',
        sortDir: 'desc',
        status: ['newLead'],
        caseManager: user.user_id
      }
    })
  }

  // if logged-in user has 'partners' in their app_metadata, push them to their partners
  useEffect(() => {
    if (user?.app_metadata?.partners && user?.roles.length === 1 && user.roles.find(role => role.name === 'partner')) {
      if (!router.pathname.includes('/partners')) {
        if (user.app_metadata.partners.length === 1) { // if there's only one partnerSlug, send them there
          router.push(`/partners/${user.app_metadata.partners[0]}`)
        } else { // else send them to /partners to choose a partnerSlug
          router.push('/partners')
        }
      }
    }
  }, [user, router])

  // Setup Menu contents based on if user is logged in or not
  const [menuItems, setMenuItems] = useState([])
  useEffect(() => {
    const menu = []
    if (user) {
      for (const menuItem of navigationMenu) {
        // if you don't have the permission to see the menu item, don't add it to the menu
        if (!hasAccess('page:' + menuItem.page, accessRules, user)) continue
        menu.push({ key: menuItem.page, label: menuItem.label, route: '/' + (menuItem?.route === '' ? '' : menuItem.route || menuItem.page), load: menuItem.loadingText, icon: menuItem.icon })
      }
    } else {
      menu.push({ key: 'login', label: 'Login', route: '/api/auth/login', icon: <AccountCircle /> })
    }
    setMenuItems(menu)
  }, [showLocal, showStaging, user, accessRules])

  useEffect(() => {
    if (priorityNewLeadsData?.data?.opportunitiesPriorityNew?.length) setPriorityNewLeadsCount(priorityNewLeadsData.data.opportunitiesPriorityNew.length)
  }, [priorityNewLeadsData, priorityNewLeadsError, priorityNewLeadsIsValidating])

  const versionModifier = (showLocal ? ' LOCAL - ' + process.env.RI_PRODCLONE : (showStaging ? ' STAGING - ' + process.env.RI_PRODCLONE : String()))

  let appBarClass = headerStyles.appBar
  if (showStaging) appBarClass = classNames(headerStyles.appBar, headerStyles.appBarStaging)
  if (showLocal) appBarClass = classNames(headerStyles.appBar, headerStyles.appBarLocal)

  return <>
    <div id='headerDiv'>

      <AppBar
        id='headerAppBar'
        position='fixed'
        className={classNames(appBarClass, {
          [headerStyles.appBarShift]: false
        })}
      >

        <Toolbar position='sticky' disableGutters={!open}>
          {props.showMenu && user && user?.permissions?.includes && user.permissions.includes('read:mainSideMenu') &&
            <IconButton
              disabled={loading.isLoading}
              color='inherit'
              aria-label='Open drawer'
              onClick={handleDrawerOpen}
              className={classNames('qa-mainMenu', headerStyles.menuButton, {
                [headerStyles.hide]: open
              })}
              size="large">
              <MenuIcon />
            </IconButton>}
          <img alt='header logo' className={headerStyles.logo} style={{ height: '65px' }} src='/header_logo.png' />
          <Typography id='version' variant='caption' color='inherit' className={headerStyles.appVersion}>
            (v.{getVersion()}) {(showStaging || showLocal) && versionModifier}
          </Typography>
          {user && (
            <>
              <Grid id='headerGrid' item xs={12} className={headerStyles.headerGridContainer}>
                {headerBoard}
              </Grid>
              {hasAccess('component:globalSearch', accessRules, user) &&
                <GlobalSearch />}
              {user?.permissions?.includes('create:bug') &&
                <BugReportDialog />}
              {user?.permissions?.includes('read:supporticle') &&
                <Support
                  user={user}
                  inDrawer
                />}
              <IconButton
                id='qa-userMenu'
                className='qa-userMenu'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleUserMenu}
                color='inherit'
                size="large">
                <Badge
                  badgeContent={priorityNewLeadsCount + unReadNotificationsCount + communicationsCount}
                  max={999} overlap="circular"
                  color={priorityOneNotifications.length ? 'error' : 'secondary'}
                  invisible={!user?.permissions?.includes || !user.permissions.includes('read:priorityNewLeads') || (priorityNewLeadsCount + unReadNotificationsCount + communicationsCount) === 0}
                >
                  <img alt='profile picture' src={user?.picture || '/blank-profile-picture-973460_640.png'} style={{ borderRadius: '100%', height: '2em' }} />
                </Badge>
              </IconButton>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElement}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElement)}
                onClose={handleUserMenuClose}
              >
                <List>
                  <Link href='#' underline="hover">
                    <ListItem
                      className='qa-userMenu-logout'
                      aria-label='logout'
                      button
                      key='logout'
                      onClick={handleUserMenuLogout}
                    >
                      <ListItemIcon>
                        <img alt='profile picture' src={user?.picture || '/blank-profile-picture-973460_640.png'} style={{ borderRadius: '100%', height: '2em' }} />
                      </ListItemIcon>
                      <ListItemText primary='Logout' />
                    </ListItem>
                  </Link>
                  {hasAccess('page:myProfile', accessRules, user) &&
                    <Link href='/myProfile' underline="hover">
                      <ListItem
                        className='qa-userMenu-profile'
                        aria-label='profile'
                        button
                        key='myProfileLink'
                      >
                        <ListItemIcon>
                          <PersonOutline />
                        </ListItemIcon>
                        <ListItemText primary='My Profile' />
                      </ListItem>
                    </Link>}
                  {hasAccess('page:notifications', accessRules, user) &&
                    <Link underline="hover">
                      <ListItem
                        className='qa-userMenu-notifications'
                        aria-label='notifications'
                        button
                        key='notificationsLink'
                        id='notificationIconButton'
                        onClick={event => {
                          setNotificationsAnchorElement(event.currentTarget)
                        }}
                      >
                        <ListItemIcon>
                          <Badge
                            badgeContent={unReadNotificationsCount}
                            max={999}
                            color={priorityOneNotifications.length ? 'error' : 'secondary'}
                          >
                            {!!(priorityOneNotifications.length) && <NotificationImportant />}
                            {!!(notifications.length) && priorityOneNotifications.length === 0 && <Notifications />}
                            {notifications.length === 0 && <NotificationsOff />}
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary='Notifications' />
                      </ListItem>
                    </Link>}
                  {user?.permissions?.includes && user.permissions.includes('read:priorityNewLeads') && !!(priorityNewLeadsCount) &&
                    <Link href='#' underline="hover">
                      <ListItem
                        className='qa-userMenu-newLeads'
                        aria-label='newLeads'
                        button
                        key='priorityNewLeads'
                        onClick={handleMyNewLeadsClick}
                      >
                        <ListItemIcon>
                          <Badge
                            badgeContent={priorityNewLeadsCount}
                            max={999}
                            color='secondary'
                            invisible={false}
                          >
                            <PriorityHigh />
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary='My New Leads' />
                      </ListItem>
                    </Link>}
                  {hasAccess('page:communicationDashboard', accessRules, user) &&
                    <Link href='/communicationDashboard' underline="hover">
                      <ListItem
                        className='qa-userMenu-communicationDashboard'
                        aria-label='communicationDashboard'
                        button
                        key='communicationDashboard'
                      >
                        <ListItemIcon>
                          <Badge
                            badgeContent={communicationsCount}
                            max={999}
                            color='secondary'
                            invisible={false}
                          >
                            <MessageIcon />
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary='Unread Communications' />
                      </ListItem>
                    </Link>}
                  {user?.permissions?.includes('create:testBug') &&
                    <Link href='/myProfile' underline="hover">
                      <ListItem
                        className='qa-userMenu-profile'
                        aria-label='profile'
                        button
                        key='myProfileLink'
                      >
                        <ListItemIcon>
                          <BugReport />
                        </ListItemIcon>
                        <FormControl>
                          <InputLabel>Bug Generator</InputLabel>
                          <Select value=''style={{ width: '132px' }} >
                            <MenuItem
                              onClick={() => {
                                throw new Error('Test-Bug, not a real error, but just so you can see what it looks like.')// new test error
                              }}
                            >
                              Test Error
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                console.info(e.something.else)
                              }}
                            >
                              Test Value of Undefined
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                try {
                                  throw new Error('An error happened, we knew it could happen and handled it, but it could be an issue somewhere else that needs to be fixed.')// new test error
                                } catch (error) {
                                  consoleError(thisFile, error)
                                }
                              }}
                            >
                              Caught Error
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                consoleError('Good Luck Finding this')
                                for (let index = 0; index < 20; index++) {
                                  console.error(index, `Minus harum tempore minus. Eius atque voluptates vero. Est ipsam odit mollitia architecto aliquam necessitatibus. Consequatur atque voluptas laudantium.Autem doloremque non. Odio tenetur sint provident corrupti. Atque itaque assumenda.Doloribus cumque aut sit possimus. Soluta voluptatem sint quia in mollitia id hic odio. Hic harum id at rem. Reiciendis rerum ut et iusto. Est non tempore eaque et reiciendis praesentium eveniet quam eaque.`)
                                }
                              }}
                            >
                              Lost Error
                            </MenuItem>
                          </Select>
                        </FormControl>

                      </ListItem>
                    </Link>
                  }
                  {hasAccess('component:autoAssignQueue', accessRules, user) &&
                    <Link underline="hover">
                      <ListItem
                        className='qa-userMenu-userQueue'
                        aria-label='userQueue'
                        button
                        key='userQueue'
                        id='userQueue'
                        onClick={event => {
                          setUserQueueAnchorElement(event.currentTarget)
                        }}
                      >
                        <ListItemIcon>
                          <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Auto Assign Queue' />
                      </ListItem>
                    </Link>}
                </List>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
    <br />
    {props.showMenu &&
      <Drawer anchor='left' open={open} onClose={handleDrawerClose}>
        <div className={headerStyles.toolbar}>
          <IconButton onClick={handleDrawerClose} size="large">
            {theme?.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <Tooltip title={item.label} key={item.key} placement='right'>
              <Link href={`${item.route}`} underline="hover">
                <ListItem
                  className={'qa-mainMenu-' + item.label.toLowerCase().replace(/\/ /gi, String()).replace(/ /gi, '-').replace(/\(/gi, String()).replace(/\)/gi, String())}
                  button key={item.key}
                  selected={menuItemSelected === index}
                  onClick={() => handleMenuItemClick(item, index)}
                >
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            </Tooltip>
          ))}
        </List>
      </Drawer>}
    {user && !(loading.isLoading) && (!!(notificationsAnchorElement) || priorityOneNotifications.length) &&
      <Drawer
        anchor='right'
        onClose={() => setNotificationsAnchorElement(null)}
        open={!!(notificationsAnchorElement)}
        disableEnforceFocus
      >
        <div style={{ width: '80vw' }}>
          <div>
            <IconButton
              onClick={(event) => {
                event.preventDefault()
                setNotificationsAnchorElement(null)
                user && refetchNotifications()
              }}
              size="large">
              <ChevronRight />Notifications
            </IconButton>
          </div>
          <Divider />
          {user &&
            <UserNotifications
              user={user}
              isWidget
            />}
        </div>
      </Drawer>}
    {user && !(loading.isLoading) && !!(userQueueAnchorElement) && hasAccess('component:autoAssignQueue', accessRules, user) &&
      <Drawer
        anchor='right'
        onClose={() => setUserQueueAnchorElement(null)}
        open={!!(userQueueAnchorElement)}
        disableEnforceFocus
        style={{ zIndex: 3000 }}
      >
        <div style={{ width: '650px' }}>
          <div>
            <IconButton
              onClick={(event) => {
                event.preventDefault()
                setUserQueueAnchorElement(null)
                user && refetchNotifications()
              }}
              size="large">
              <ChevronRight />Auto Assign Queue
            </IconButton>
          </div>
          <Divider />
          {user &&
            <AutoAssignUserQueue
              user={user}
              headerStyles={headerStyles}
            />}
        </div>
      </Drawer>}
  </>
}

export default (Header)
