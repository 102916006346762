/* eslint-disable react/display-name */
import React, { useEffect } from 'reactn'
import Loading, { LoadingAnimation } from './Loading'
import Header from './Header'
import PropTypes from 'prop-types'
// import CallEndIcon from '@mui/icons-material/CallEnd'
// import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
/* eslint-disable no-unused-vars */
import {
  clientWindow, consoleATTN, consoleDev, jsonStr, hasAccess, objectValByPath, objectToObjectPathArray,
  themePathsArray, setThemeMode
} from '../../src/helpers/helper'
/* eslint-enable no-unused-vars */
// import dynamic from 'next/dynamic'
import themeBuilder from '../../src/theme'
import { CustomThemeProvider } from '../../src/theme'
// import { ThemeProvider } from '@mui/material/styles'
import Unauthorized from '../Common/Unauthorized'
import { SnackbarProvider } from 'notistack'
import Welcome from '../Common/Welcome'

const thisFile = 'App ' // eslint-disable-line no-unused-vars

const App = (props) => {
  // Extract expected props
  const { children, user, showMenu = true, location = 'true', snackProvider = false, maxSnacks = 3, accessRules = [], headerBoard, mainStyle = { marginTop: '4em' }, hideHeader = false } = props
  const theme = themeBuilder(user?.app_metadata?.themeMode)

  // Add Chatlio Slack chat widget snippet if it's a firm user
  useEffect(() => {
    // this is an exception to the the accessRules length check since it adds a dom element that is injected from outside our code
    if (user && accessRules?.length && hasAccess('component:chatlioWidget', accessRules, user)) {
      const script = document.createElement('script')
      script.src = '/chatlioWidget.js'
      script.async = true
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [user, accessRules])

  if (user === undefined) { // User is still being checked if undefined. Will be null or set once checked.
    return (
      <div data-testid='qa-app-loading'>
        <LoadingAnimation
          {...{
            message: 'Authenticating User'
          }}
        />
      </div>
    )
  } else { // user is done being checked, will be either null for not logged in or will be set to logged in user
    let content
    if (user && location && accessRules?.length) { // logged in user and permissions to be checked
      if (hasAccess(location, accessRules, user)) { // check if user has permissions
        content = children
      } else {
        // if the user didn't have ALL of the permissions in the accessRules[location], then content will be unset
        if (!content) content = (<div>    <Unauthorized />  </div>) // User is Unauthorized to access this page
      }
    } else if ((user || user === null) && location === false) { // logged in or guest user and non gate page, give it to em
      // ************* NO GATE ************
      content = children
    } else if (accessRules?.length === 0) {
      content = <div data-testid='qa-app-loading'>
        <LoadingAnimation
          {...{
            message: 'Authorizing User'
          }}
        />
      </div>
    } else {
      // No user here could be from wrong app version.  Welcome component will check local storage
      // For a re-direct
      content = <Welcome /> // else show User Login/Welcome screen
    }
    if (snackProvider) {
      content = <SnackbarProvider maxSnack={maxSnacks} preventDuplicate dense>{content}</SnackbarProvider>
    }
    if (location === 'page:phone') {
      if (process.env.NODE_ENV !== 'test' && user && hasAccess('page:phone', accessRules, user) && user.email.includes('@caseopp.com')) {
        return (
          <div>
            {content}
          </div>
        )
      }
    }
    return (
      <div>
        {/* Uses builtTheme prop to know that palette mode had been processed.  Otherwise themeBuilder() will be called with default variables if none provided. */}
        <CustomThemeProvider builtTheme={theme}>
          <Loading />
          {(!hideHeader) && <Header user={user} showMenu={showMenu} theme={theme} accessRules={accessRules} headerBoard={headerBoard} />}
          <main style={mainStyle}>
            {content}
          </main>
        </CustomThemeProvider>
      </div>
    )
  }
}

App.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.any,
  user: PropTypes.object,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  location: PropTypes.string,
  accessRules: PropTypes.array,
  headerBoard: PropTypes.any,
  hideHeader: PropTypes.bool
}
// export default withStyles(styles, { withTheme: true })(App)
export default (App)
