import { useState } from 'reactn'
import { useMutation } from '@apollo/client'
// MUI Core
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import BugReportForm from './BugReportForm'
import { BugReport } from '@mui/icons-material'
import { newBugMutation } from '../../src/gql/bugReportGQL'
import { scrubTypename } from '../../src/helpers/helper' // eslint-disable-line no-unused-vars
import useRIUser from '../../src/hooks/riUser'

const thisFile = 'components/Common/BugReportDialog.js ' // eslint-disable-line no-unused-vars

const BugReportDialog = (props) => {
  const { user, accessRules } = useRIUser()
  const [showDialog, setShowDialog] = useState(props.showDialog || false)
  const [createBugReportMutation] = useMutation(newBugMutation)

  // this function creates a bug report from the bug report form info
  const handleCreateBugReport = async (bug) => {
    const scrubbedBug = scrubTypename(bug)
    try {
      createBugReportMutation({
        variables: {
          input: scrubbedBug
        }
      }).catch((error) => {
        return error
      })
    } catch (error) {
      return error
    }
    return true
  }

  return <>
    {!showDialog &&
    <IconButton
      color='inherit'
      aria-label='Open dialog'
      onClick={() => setShowDialog(true)}
      size="large">
      <Tooltip title='Report Bug' placement='bottom'>
        <BugReport width='64px' height='64px' />
      </Tooltip>
    </IconButton>}
    <BugReportForm
      mutateBug={handleCreateBugReport}
      setOpenBugExpandedView={setShowDialog}
      openBugExpandedView={showDialog}
      toggleModal={props.toggleModal}
      user={user}
      accessRules={accessRules}
    />
  </>
}

export default BugReportDialog
