/* eslint-disable react/display-name */
import { useApolloClient } from '@apollo/client'
// MUI Core
import Fab from '@mui/material/Fab'
import { AddAlert, Visibility } from '@mui/icons-material'
import { GridToolbar, DataGridPremium, GridActionsCellItem } from '@mui/x-data-grid-premium'
import React, { useEffect, useRef, useState } from 'react'
import CreateEditNotificationDialog from '../../components/Notifications/CreateEditNotificationDialog'
import ViewNotificationDialog from '../../components/Notifications/ViewNotificationDialog'
import { gqlErrorMessages } from '../../graphql/client'
import { myNotificationsQuery } from '../../src/gql/notificationGQL'
import { scrubTypename, consoleATTN } from '../../src/helpers/helper' // eslint-disable-line no-unused-vars
import { getAllUsers } from '../../src/helpers/userHelper'
import notificationStyles from '../../src/styles/notifications.module.css'

const thisFile = 'components/Notifications/userNotifications.js ' // eslint-disable-line no-unused-vars

const UserNotifications = (props) => {
  const {
    user,
    isWidget = false
  } = props

  const gqlClientRef = useRef(useApolloClient())
  const [userNotifications, setUserNotifications] = useState([])
  const [notificationsLoading, setNotificationsLoading] = useState(false)
  const [viewNotification, setViewNotification] = useState()
  const [createEditNotification, setCreateEditNotification] = useState()

  /**
   * queries all user notifications and if un-acknowledged priority 1 notifications
   * are found they will auto open for immediate acknowledgement
   */
  const queryUserNotifications = async () => {
    const userNotificationsResult = await gqlClientRef.current.query({
      query: myNotificationsQuery,
      variables: {
        input: {
          userId: user.user_id
        }
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'queryQuestions error:', gqlErrorMessages(error))
    })
    if (userNotificationsResult) setNotificationsLoading(userNotificationsResult.loading)
    if (userNotificationsResult.data.myNotifications) { // don't look for length as it may be []
      const myNotifications = scrubTypename(userNotificationsResult.data.myNotifications)
      setUserNotifications(myNotifications)
      if (myNotifications.filter(notification => (notification.priority === 1 && !notification.recipients.find(recipient => recipient.userId === user.user_id).acknowledged)).length > 0) {
        setViewNotification(myNotifications.filter(notification => (notification.priority === 1 && !notification.recipients.find(recipient => recipient.userId === user.user_id).acknowledged))[0])
      }
    }
  }

  // Get a list of all users on initial render
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(false)
  useEffect(() => {
    setUsersLoading(true)
    getAllUsers(setUsers, setUsersLoading)
  }, [])

  /**
   * calls the userNotification query once user value is set
   */
  useEffect(() => {
    user && queryUserNotifications()
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * returns a class name based on the current priority
   * @param {Number} priority
   * @returns {String} className
   */
  const getPriorityClassName = (priority) => {
    switch (priority) {
      case 1:
        return notificationStyles.priority_1
      case 2:
        return notificationStyles.priority_2
      case 3:
        return notificationStyles.priority_3
      case 4:
        return notificationStyles.priority_4
      default:
        break
    }
  }

  /**
   * takes a userId and returns the readable name
   * @param {String} userId
   * @returns {String} user name
   */
  const getRecipientName = (userId) => {
    const name = users?.find(user => user.user_id === userId)?.name
    return name || userId
  }

  const notificationGridColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 50,
      filterable: false,
      hide: true
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={'view' + params.row.id}
          aria-label={'View Notification'}
          icon={<Visibility />}
          onClick={() => {
            setViewNotification(params.row)
          }}
        />
      ]
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      valueFormatter: (params) => {
        return (new Date(params.value)).toDateString()
      },
      type: 'dateTime'
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 130,
      cellClassName: (params) => (getPriorityClassName(params.value)),
      type: 'number'
    },
    {
      field: 'recipients',
      headerName: 'Acknowledged',
      flex: 1,
      cellClassName: (params) => (params.value === 'True' ? notificationStyles.acknowledged : notificationStyles.pending),
      valueGetter: (params) => {
        return params.value.find(recipient => recipient.userId === user.user_id).acknowledged ? 'True' : 'False'
      },
      type: 'singleSelect',
      valueOptions: ['True', 'False']
    },
    {
      field: 'sendingUserId',
      headerName: 'From',
      flex: 1,
      valueGetter: (params) => (getRecipientName(params.value))
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 2
    },
    {
      field: 'done',
      headerName: 'Done',
      flex: 1,
      type: 'singleSelect',
      valueOptions: ['True', 'False'],
      valueGetter: (params) => {
        return params?.row?.recipients?.find(recipient => recipient.userId === user.user_id)?.done ? 'True' : 'False'
      },
      cellClassName: (params) => (params?.row?.recipients?.find(recipient => recipient.userId === user.user_id)?.done ? notificationStyles.acknowledged : notificationStyles.pending)
    },
    {
      field: 'snooze',
      headerName: 'Snoozed',
      flex: 2,
      type: 'dateTime',
      headerClassName: () => (notificationStyles.snoozed),
      valueGetter: (params) => {
        return (new Date(params?.row?.recipients?.find(recipient => recipient.userId === user.user_id)?.snooze))
      },
      cellClassName: (params) => ((new Date(params?.row?.recipients?.find(recipient => recipient.userId === user.user_id)?.snooze) > new Date()) ? notificationStyles.snoozed : null)
    }
  ]
  return (
    <>
      <Fab
        variant='extended'
        aria-label='add-notification'
        color='secondary'
        style={{ width: '20%', marginTop: '1em', marginBottom: '1em' }}
        onClick={() => {
          setCreateEditNotification({
            id: 'Create',
            recipients: [
              {
                userId: user.user_id,
                acknowledged: false,
                done: false
              }
            ],
            recipientGroup: 'individual'
          })
        }}
      >
        <AddAlert style={{ marginRight: '0.5em' }} />
        <div className='qa-add-campaign'>
          Add New Notification
        </div>
      </Fab>
      {createEditNotification &&
        <CreateEditNotificationDialog
          style={{ width: '1000px' }}
          createEditNotificationHook={[createEditNotification, setCreateEditNotification]}
          users={users}
          myNotification
          cellClass={getPriorityClassName(createEditNotification.priority)}
          queryNotifications={queryUserNotifications}
          user={user}
        />}
      <div id='userNotifications' style={{ height: '92vh', width: '100%' }}>
        <DataGridPremium
          columns={notificationGridColumns}
          rows={userNotifications}
          loading={notificationsLoading || usersLoading}
          rowHeight={38}
          disableMultipleSelection
          pagination
          autoPageSize
          components={{
            Toolbar: !isWidget && GridToolbar
          }}
          componentsProps={{
            filterPanel: {
              columnsSort: 'asc'
            }
          }}
          disableSelectionOnClick
          filterModel={isWidget
            ? {
              items: [
                {
                  id: 1,
                  columnField: 'done',
                  operatorValue: 'is',
                  value: 'False'
                },
                {
                  id: 2,
                  columnField: 'snooze',
                  operatorValue: 'onOrBefore',
                  value: new Date().toISOString()
                }
              ]
            }
            : undefined}
          sortModel={[
            {
              field: 'recipients',
              sort: 'asc'
            },
            {
              field: 'priority',
              sort: 'asc'
            },
            {
              field: 'createdAt',
              sort: 'desc'
            }
          ]}
        />
      </div>
      {viewNotification?.id &&
        <ViewNotificationDialog
          viewNotificationHook={[viewNotification, setViewNotification]}
          cellClass={getPriorityClassName(viewNotification.priority)}
          queryUserNotifications={queryUserNotifications}
          getRecipientName={getRecipientName}
          user={user}
        />}
    </>
  )
}

export default UserNotifications
