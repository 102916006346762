import { useState, useRef, useEffect } from 'reactn'
import { useApolloClient } from '@apollo/client'
// MUI Core
import SnackbarContent from '@mui/material/SnackbarContent'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import { updateUserNotificationMutation } from '../../src/gql/notificationGQL'
import { gqlErrorMessages } from '../../graphql/client'
import { Close, Warning } from '@mui/icons-material'
import classNames from 'classnames'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker'
import notificationStyles from '../../src/styles/notifications.module.css'
import { makeCSV } from '../../src/helpers/csvHelper'
import { consoleError } from '../../src/helpers/helper'
import { Typography } from '@mui/material'

const thisFile = 'components/Common/Notifications/ViewNotificationDialog.js ' // eslint-disable-line no-unused-vars

const ViewNotificationDialog = (props) => {
  const {
    viewNotificationHook,
    cellClass = notificationStyles.priority_1,
    queryUserNotifications,
    getRecipientName = (id) => (id),
    user
  } = props
  const gqlClientRef = useRef(useApolloClient())
  const [viewNotification, setViewNotification] = viewNotificationHook
  const [updateError, setUpdateError] = useState(false)
  const [disableClose, setDisableClose] = useState(false)
  const [disableAcknowledged, setDisableAcknowledged] = useState(false)
  const [doneClicked, setDoneClicked] = useState(false)
  const [addSnooze, setAddSnooze] = useState(false)
  const [snoozeDate, setSnoozeDate] = useState(new Date())
  const [downloadCSV, setDownloadCSV] = useState({ file: null, fileName: 'loading' })

  /**
   * runs the mutation to update a notification with the users acknowledgement
   * on success it wipes the dialog state and re-queries the notifications
   */
  const acknowledgeNotification = async () => {
    const editNotificationsResult = await gqlClientRef.current.mutate({
      mutation: updateUserNotificationMutation,
      variables: {
        input: {
          id: viewNotification.id,
          acknowledged: true
        }
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'acknowledge notification error:', gqlErrorMessages(error))
    })
    if (!editNotificationsResult.data.updateUserNotification.value) {
      setUpdateError(true)
      // throw new Error(thisFile, 'editNotifications mutation did not save')
    } else {
      setViewNotification()
      queryUserNotifications()
    }
  }

  /**
   * runs the mutation to update a notification with the users acknowledgement
   * and mark the notification as done
   * on success it wipes the dialog state and re-queries the notifications
   */
  const doneNotification = async () => {
    const editNotificationsResult = await gqlClientRef.current.mutate({
      mutation: updateUserNotificationMutation,
      variables: {
        input: {
          id: viewNotification.id,
          done: true,
          acknowledged: true
        }
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'done notification error:', gqlErrorMessages(error))
    })
    if (!editNotificationsResult.data.updateUserNotification.value) {
      setUpdateError(true)
      // throw new Error(thisFile, 'editNotifications mutation did not save')
    } else {
      setViewNotification()
      queryUserNotifications()
    }
  }

  /**
   * runs the mutation to update the snooze date on a notification
   * on success it wipes the dialog state and re-queries the notifications
   */
  const snoozeNotification = async () => {
    const snoozeInput = {
      id: viewNotification.id,
      done: false,
      snooze: snoozeDate
    }
    if (viewNotification.priority !== 1) snoozeInput.acknowledged = false
    const snoozeNotificationsResult = await gqlClientRef.current.mutate({
      mutation: updateUserNotificationMutation,
      variables: {
        input: snoozeInput
      },
      fetchPolicy: 'no-cache'
    }).catch((error) => {
      throw new Error(thisFile, 'done notification error:', gqlErrorMessages(error))
    })
    if (!snoozeNotificationsResult.data.updateUserNotification.value) {
      setUpdateError(true)
      // throw new Error(thisFile, 'editNotifications mutation did not save')
    } else {
      setViewNotification()
      queryUserNotifications()
    }
  }

  /**
   * IF notification is priority 1 and not acknowledged closing the notification is not an option
   * IF notification is already acknowledged, user can not re-acknowledge to prevent useless db calls
   */
  useEffect(() => {
    setDisableClose((viewNotification.priority === 1 && !viewNotification.recipients.find(recipient => recipient.userId === user.user_id)?.acknowledged))
    setDisableAcknowledged(viewNotification.recipients.find(recipient => recipient.userId === user.user_id)?.acknowledged)
  }, [user.user_id, viewNotification])

  const convertJsonLinkToCsv = async (url) => {
    try {
      let splitUrl = url.split('/')
      splitUrl = splitUrl[splitUrl.length - 1]?.split('?')?.[0]
      splitUrl = splitUrl.replace('.json', '')
      if (splitUrl.length === 13) {
        splitUrl = '' // no title just unix timestamp
      }
      const response = await fetch(url)
      const json = await response.json()
      // if questions and answers are included convert the array to root columns
      if (json?.[0]?.QandA) {
        for (const row of json) {
          for (const qa of row.QandA) {
            row[qa.Q] = qa.A
          }
          delete row.QandA
        }
      }
      if (json?.[0]?.customFields) {
        for (const row of json) {
          for (const customFields of row.customFields) {
            if (customFields?.length) {
              for (const field of customFields) {
                row[field.slug] = field.value
              }
            }
          }
          delete row.customFields
        }
      }
      const csv = makeCSV(json, { withType: false })
      if (csv) {
        // make timestamp for file name
        const date = Date.now()
        // set state for download link
              const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' }) // eslint-disable-line
        setDownloadCSV({
          file: blob,
          fileName: splitUrl ? splitUrl + '.csv' : 'Opportunities_' + date + '.csv'
        })
      }
    } catch (error) {
      consoleError(thisFile, ' error: ', error)
    }
  }

  useEffect(() => {
    if (viewNotification.linkType === 'download') {
      convertJsonLinkToCsv(viewNotification.link)
    }
  }, [viewNotification])

  return <>
    <Dialog id='notificationDialog' open={!!(viewNotification?.id)}>
      <DialogTitle className={cellClass} />
      <DialogContent
        style={{ backgroundColor: 'unset' }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            {'Sent on: ' + (new Date(viewNotification.createdAt)).toDateString()}
          </Grid>
          <Grid item xs={12}>
            {'From: ' + getRecipientName(viewNotification.sendingUserId)}
          </Grid>
          <Grid item xs={2}>
            {'Priority: ' + viewNotification.priority}
          </Grid>
          <Grid item xs={12} style={{ backgroundColor: 'lightgray' }}>
            <pre style={{ font: 'inherit', marginTop: 0, marginBottom: 0 }}>{viewNotification.message}</pre>
          </Grid>
          {viewNotification.link &&
          <Grid item xs={12}>
            {viewNotification.linkType === 'download' && downloadCSV.file &&
              <a href={window.URL.createObjectURL(downloadCSV.file)}
                download={downloadCSV.fileName}>{downloadCSV.fileName}</a>
            }
            {viewNotification.linkType === 'download' && !downloadCSV.file && downloadCSV.fileName !== 'loading' &&
              <Typography>-Link Expired-</Typography>
            }
            {viewNotification.linkType === 'download' && !downloadCSV.file && downloadCSV.fileName === 'loading' &&
              <Typography>{downloadCSV.fileName}</Typography>
            }
            {viewNotification.linkType !== 'download' &&
            <Link href={viewNotification.link} underline="hover">
              {viewNotification.link}
            </Link> }
          </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          aria-label='Close'
          disabled={disableClose}
          color='primary'
          onClick={() => setViewNotification()}
        >
          Close
        </Button>
        <Button
          aria-label='Snooze'
          disabled={disableClose}
          color='primary'
          onClick={() => setAddSnooze(true)/* snoozeNotification(new Date('2022-03-17')) */}
        >
          Snooze
        </Button>
        <Button
          aria-label='Acknowledge'
          color='primary'
          disabled={disableAcknowledged}
          onClick={() => {
            acknowledgeNotification()
          }}
        >
          Acknowledge
        </Button>
        {!doneClicked &&
          <Button
            aria-label='Done'
            color='primary'
            // disabled={disableAcknowledged}
            onClick={() => {
              setDoneClicked(true)
            }}
          >
            Done
          </Button>}
        {doneClicked &&
          <Button
            aria-label='Are you sure?'
            color='primary'
            variant='contained'
            // disabled={disableAcknowledged}
            onClick={() => {
              doneNotification()
            }}
          >
            Are you sure?
          </Button>}
      </DialogActions>
    </Dialog>
    {addSnooze &&
      <Dialog open={addSnooze}>
        <DialogTitle>Snooze Date</DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              variant='static'
              id='post-date'
              value={snoozeDate || new Date()}
              onChange={(e) => {
                setSnoozeDate(e)
              }}
              showTodayButton
              disablePast
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Cancel'
            disabled={disableClose}
            color='primary'
            onClick={() => {
              setAddSnooze(false)
            }}
          >
            Cancel
          </Button>
          <Button
            aria-label='Set Snooze Date'
            disabled={disableClose}
            color='primary'
            onClick={() => {
              setAddSnooze(false)
              snoozeNotification()
            }}
          >
            Set Snooze Date
          </Button>
        </DialogActions>
      </Dialog>}
    {updateError &&
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={updateError}
        autoHideDuration={6000}
        onClose={() => {
          setUpdateError(false)
        }}
      >
        <SnackbarContent
          className={notificationStyles.warning}
          aria-describedby='message-id'
          message={
            <span id='message-id' className={notificationStyles.message}>
              <Warning className={classNames(notificationStyles.icon, notificationStyles.iconVariant)} />
              Acknowledge Notification Failed
            </span>
          }
          action={[
            <IconButton
              key='close'
              aria-label='Close'
              color='inherit'
              onClick={() => {
                setUpdateError(false)
              }}
              size="large">
              <Close className={classNames(notificationStyles.icon, notificationStyles.iconVariant)} />
            </IconButton>
          ]}
        />
      </Snackbar>}
  </>
}

export default ViewNotificationDialog
