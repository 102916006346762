import gql from 'graphql-tag'

export const accessQuery = gql`
  query access {
    access {
      id
      location
      permissions
    }
  }
`
export const createAccessMutation = gql`
  mutation createAccess($input: CreateAccessInput){
    createAccess(input: $input){
      id
      location
      permissions
    }
  }
`
export const updateAccessMutation = gql`
  mutation updateAccess($input: UpdateAccessInput){
    updateAccess(input: $input){
      id
      location
      permissions
    }
  }
`

export const rolesQuery = gql`
  query roles {
    roles {
      _id
      roleId
      description
      id
      name
      permissions {
        _id
        permissionName
        permissionDescription
      }
    }
  }
`

export const createRoleMutation = gql`
  mutation createRole($input: CreateRoleInput){
    createRole(input: $input){
      roleId
      description
      id
      name
      permissions {
        permissionName
        permissionDescription
      }
    }
  }
`

export const updateRoleMutation = gql`
  mutation updateRole($input: UpdateRoleInput){
    updateRole(input: $input){
      roleId
      description
      id
      name
      permissions {
        permissionName
        permissionDescription
      }
    }
  }
`

export const deleteRoleMutation = gql`
  mutation deleteRole($input: DeleteRoleInput){
    deleteRole(input: $input){
      user_id
      name
      roleName
    }
  }
`

export const permissionsQuery = gql`
  query permissions {
    permissions {
      _id
      permissionName
      permissionDescription
    }
  }
`

export const createPermissionMutation = gql`
  mutation createPermission($input: CreatePermissionInput){
    createPermission(input: $input){
      permissionName
      permissionDescription
    }
  }
`

export const updatePermissionMutation = gql`
  mutation updatePermission($input: UpdatePermissionInput){
    updatePermission(input: $input){
      permissionName
      permissionDescription
    }
  }
`

export const deletePermissionMutation = gql`
  mutation deletePermission($id: String!){
    deletePermission(id: $id){
      permissionName
      permissionDescription
    }
  }
`

module.exports = {
  accessQuery,
  createAccessMutation,
  updateAccessMutation,
  rolesQuery,
  createRoleMutation,
  updateRoleMutation,
  deleteRoleMutation,
  permissionsQuery,
  createPermissionMutation,
  updatePermissionMutation,
  deletePermissionMutation
}
