import gql from 'graphql-tag'

const notificationsQuery = gql`
  query notifications($input: NotificationsInput) {
    notifications(input: $input) {
      id
      createdAt
      postDate
      updatedAt
      message
      priority
      recipientGroup
      recipients {
        id
        userId
        acknowledged
        done
        snooze
      }
      sendingUserId
      link
      linkType
    }
  }
`

const myNotificationsQuery = gql`
  query myNotifications($input: MyNotificationsInput) {
    myNotifications(input: $input) {
      id
      createdAt
      updatedAt
      message
      priority
      recipients {
        id
        userId
        acknowledged
        done
        snooze
      }
      sendingUserId
      link
      linkType
    }
  }
`

const createNotificationMutation = gql`
  mutation createNotification($input: CreateNotificationInput) {
    createNotification(input: $input) {
      value
    }
  }
`

const updateNotificationMutation = gql`
  mutation updateNotification($input: UpdateNotificationInput) {
    updateNotification(input: $input) {
      value
    }
  }
`

const updateUserNotificationMutation = gql`
  mutation updateUserNotification($input: updateUserNotificationInput){
    updateUserNotification(input: $input) {
      value
    }
  }
`

const deleteNotificationMutation = gql`
  mutation deleteNotification($id: String!) {
    deleteNotification(id: $id) {
      value
    }
  }
`

module.exports = {
  notificationsQuery,
  myNotificationsQuery,
  createNotificationMutation,
  updateNotificationMutation,
  updateUserNotificationMutation,
  deleteNotificationMutation
}
