import { useLazyQuery } from '@apollo/client'
import { consoleError } from '../helpers/helper'
import { useUser } from '@auth0/nextjs-auth0'
import { accessQuery } from '../gql/accessGQL'
import { useFetchUser } from '../auth/user'
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'

// eslint-disable-next-line no-unused-vars
const thisFile = 'src/hooks/riUserHook.js '

export default function useRIUser () {
  // Get Client Side User from Auth0 cookie
  const { user: csUser, error } = useUser()
  if (error) consoleError('Error loading User: ', error.message)
  // Pull out the id once we have it so that we can use it to check that the Server Side user fetch below is accurate
  const [csUserId, setCSUserId] = useState()
  useEffect(() => {
    if (csUser?.sub) { // user_id for some reason is stored in a field called 'sub'
      setCSUserId(csUser.sub)
    }
  }, [csUser])

  // Get Server Side User from Auth0 session token
  const ssUser = useFetchUser({ autoPromptLogin: true })
  // Pull out the id once we have it so that we can compare to Client Side user id and make sure they match
  const [ssUserId, setSSUserId] = useState()
  useEffect(() => {
    if (ssUser?.user_id) {
      setSSUserId(ssUser.user_id)
    }
  }, [ssUser])

  // Verify that the SS and CS users match
  // If they match, all good, set the user to be returned to what we got from the server (which includes permissions and meta data)
  // If they Dont match, not something is amiss, throw an error
  const [user, setUser] = useState()
  useEffect(() => {
    if (ssUserId && csUserId && ssUserId !== csUserId) {
      throw Error(consoleError(thisFile, '*** User Fetched from Server was Different from Client Side User! clientSide: ', csUserId, ' | serverSide: ', ssUserId))
    } else if (ssUserId && csUserId && ssUserId === csUserId) {
      setUser(ssUser)
    }
  }, [ssUserId, csUserId, ssUser])

  // Grab data accessRules that are needed most everywhere user is and return them from this hook too
  const [getAccessRules, { data: accessRules }] = useLazyQuery(accessQuery)
  useEffect(() => {
    if (user) {
      Sentry.setUser(user)
      getAccessRules()
    }
  }, [user, getAccessRules])

  return { user, accessRules: accessRules?.access }
}
