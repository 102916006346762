import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { randomId } from '@mui/x-data-grid-generator'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton
} from '@mui/x-data-grid-premium'
import AddIcon from '@mui/icons-material/Add'
import FilterListIcon from '@mui/icons-material/FilterList'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import CheckBox from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankOutlined from '@mui/icons-material/CheckBoxOutlineBlankOutlined'

const RIGridToolBar = (props) => {
  const {
    apiRef,
    user,
    addButton = false,
    newRowDefaults = {},
    columnsButton = true,
    filterButton = true,
    densitySelector = true,
    exportButton = false,
    setShowExportTitle = () => { },
    allowDownloadData = false,
    applyFiltersButton = false,
    handleApplyFilters,
    allowApplyFilters = false,
    loading = false,
    downloadingData = false,
    bulkActions = false,
    toggleBulkActions = () => { },
    bulkActionsEnabled = false
  } = props

  const handleAddRowClick = () => {
    const id = randomId()
    apiRef.current.updateRows([{ id, ...newRowDefaults }])
    apiRef.current.setRowMode(id, 'edit')
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1
      })
    })
  }

  return (
    <GridToolbarContainer>
      {addButton && <Button color='primary'onClick={handleAddRowClick} startIcon={<AddIcon />}>
        Add
      </Button>}
      {columnsButton && <GridToolbarColumnsButton />}
      {filterButton && <GridToolbarFilterButton />}
      {densitySelector && <GridToolbarDensitySelector />}
      {exportButton && user.permissions.includes('create:newOppGridExportDownload') &&
      <LoadingButton
        loading={downloadingData}
        onClick={() => setShowExportTitle(true)}
        disabled={!allowDownloadData}
        sx={{
          fontSize: '0.8125rem',
          padding: '4px 5px',
          color: 'primary'
        }}
      >
        {!downloadingData &&
        <SaveAltIcon
          color='primary'
          size='small'
          focusable='false'
          fontSize='medium'
          sx={{
            fontSize: '18px',
            marginRight: '8px',
            marginLeft: '-2px'
          }}
        />}
        Export
      </LoadingButton>
      }
      {bulkActions && (user.permissions.includes('create:oppBulk')) &&
        <Button
          sx={{
            fontSize: '0.8125rem',
            padding: '4px 5px'
          }}
          onClick={toggleBulkActions}
          startIcon={bulkActionsEnabled ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        >
          Bulk Actions
        </Button>
      }
      {applyFiltersButton &&
        <LoadingButton
          sx={{
            fontSize: '0.8125rem',
            padding: '4px 5px',
            color: '#fafafa',
            backgroundColor: (allowApplyFilters && !loading) ? '#4caf50' : '#e0e0e0',
            '&:hover': {
              backgroundColor: (allowApplyFilters && !loading) ? '#43a047' : '#e0e0e0'
            }
          }}
          onClick={() => handleApplyFilters()}
          disabled={!allowApplyFilters || loading}
          loading={loading}
        >
          {!loading &&
            <FilterListIcon
              color={(!allowApplyFilters || loading) ? 'disabled' : '#43a047'}
              size='small'
              focusable='false'
              fontSize='medium'
              sx={{
                fontSize: '18px',
                marginRight: '8px',
                marginLeft: '-2px'
              }}
            />}
          Apply Filters
        </LoadingButton>
      }
    </GridToolbarContainer>
  )
}

export default RIGridToolBar
