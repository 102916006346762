import React, { forwardRef, useEffect, useRef, useState, useCallback } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
// MUI
import { AddOutlined, ExpandMore, ChevronRight } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import Fab from '@mui/material/Fab'
import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import { DataGridPremium, useGridApiRef, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, useKeepGroupedColumnsHidden } from '@mui/x-data-grid-premium'
// Helpers and Components
import { multipleSupporticlesQuery, singleSupporticleQuery } from '../../src/helpers/supportHelper'
import Supporticle from './Supporticle'
import SupporticleEdit from './SupporticleEdit'
import RIGridToolBar from '../Common/DataGridPremium/RIGridToolBar'

const thisFile = 'components/Support/SupporticleController ' // eslint-disable-line no-unused-vars

// eslint-disable-next-line react/display-name
const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const SupporticleController = (props) => {
  const {
    defaultSearch,
    supporticleSlug,
    campaignSlug,
    inDrawer,
    user
  } = props

  const [fireMultiSupporticleSearch, { called, refetch }] = useLazyQuery(multipleSupporticlesQuery)
  const [fireSingleSupporticleSearch, { error: singleSupporticleSearchError }] = useLazyQuery(singleSupporticleQuery)

  const [showSupporticleEditFSD, setShowSupporticleEditFSD] = useState(false)
  const [supporticleToEdit, setSupporticleToEdit] = useState({})
  const [supportRows, setSupportRows] = useState([])
  const [selectableCategories, setSelectableCategories] = useState([])
  const rowsPerPage = 10
  const apiRef = useGridApiRef()
  const routerRef = useRef({
    routerHook: useRouter()
  })
  // Initial State for the data grid premium
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: !inDrawer ? ['categories', 'subCategories'] : []
      },
      sorting: {
        sortModel: [{ field: 'categories', sort: 'asc' }]
      },
      columns: {
        columnVisibilityModel: { categories: false, subCategories: false }
      }
    }
  })
  const { routerHook: router } = routerRef.current
  const style = inDrawer ? { padding: '1em', margin: '1em' } : { padding: '1em', margin: '5em' }
  const onCloseQuery = {
    variables: {
      input: {
        wordOrPhrase: '',
        sortField: 'updatedAt',
        sortAscDesc: 'desc',
        page: 0,
        rowsPerPage: rowsPerPage
      }
    },
    fetchPolicy: 'no-cache'
  }

  // open FSD and pass it the selected supporticle (or none if new)
  const handleShowSupporticleEditOpen = (supporticle) => {
    setSupporticleToEdit(supporticle)
    setShowSupporticleEditFSD(true)
  }

  // show most-recently created/edited supporticle after edit FSD close
  const handleShowSupporticleEditClose = () => {
    !called ? fireMultiSupporticleSearch(onCloseQuery) : refetch(onCloseQuery)
    setShowSupporticleEditFSD(false)
  }

  // sets up the data grid pro for the supporticles
  const supportColumns = [
    {
      field: 'categories',
      headerName: 'Categories',
      flex: 1,
      valueGetter: ({ row }) => {
        // Ensures that the pinned supporticles are always at the top of the list
        if (row?.categories?.includes('Pinned')) {
          return '_Pinned'
        }
        return row?.categories
      }
    },
    {
      field: 'subCategories',
      headerName: 'Sub Categories',
      flex: 1
    },
    {
      // This is the icon for the master detail so we can place it where we want it to be
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF
    },
    {
      field: 'articleTitle',
      headerName: 'Title',
      flex: 1
    },
    {
      field: 'definition',
      headerName: 'Definition',
      flex: 1
    }
  ]

  const getDetailPanelContent = useCallback((supporticle) => {
    if (supporticle?.row?.slug) {
      return (
        <Supporticle
          key={supporticle.row.slug}
          permissions={user?.permissions}
          supporticle={supporticle.row}
          onShowSupporticleEditOpen={handleShowSupporticleEditOpen}
          onShowSupporticleEditClose={handleShowSupporticleEditClose}
          multipleSupporticlesQuery={multipleSupporticlesQuery}
          glossaryView
          openInNewTab={inDrawer}
          showSupporticleEditFSD={showSupporticleEditFSD}
        />
      )
    } else {
      return null
    }
  }, [handleShowSupporticleEditClose, inDrawer, showSupporticleEditFSD, user?.permissions])

  // on first load, search multiple or single depending on props
  useEffect(() => {
    let allSupporticles = []
    const getSupporticles = async () => {
      const result = await fireMultiSupporticleSearch({
        variables: {
          input: {
            wordOrPhrase: defaultSearch || '',
            sortField: defaultSearch ? 'slug' : 'term',
            sortAscDesc: 'asc',
            page: 0
          }
        },
        fetchPolicy: 'cache-and-network'
      })
      if (result?.data?.supporticlesSearch?.data.length) {
        allSupporticles = allSupporticles.concat(result.data.supporticlesSearch.data)
        setSupportRows(allSupporticles)
      }
    }
    const getSingleSupporticle = async () => {
      const result = await fireSingleSupporticleSearch({
        variables: {
          slug: supporticleSlug
        }
      })
      if (result?.data?.supporticle) {
        allSupporticles = allSupporticles.concat(result.data.supporticle)
        setSupportRows(allSupporticles)
      }
    }
    const getCampaignSupporticles = async () => {
      const result = await fireSingleSupporticleSearch({
        variables: {
          slug: campaignSlug.toLowerCase()
        }
      })
      if (result?.data?.supporticle) {
        allSupporticles = allSupporticles.concat([result.data.supporticle])
        setSupportRows(allSupporticles)
      }
    }
    !supporticleSlug && getSupporticles()
    supporticleSlug && getSingleSupporticle()
    campaignSlug && getCampaignSupporticles()
  }, [campaignSlug, defaultSearch, fireMultiSupporticleSearch, fireSingleSupporticleSearch, supporticleSlug, showSupporticleEditFSD]) // Needs to have showSupporticleEditFSD in the dependency array so the data grid will show fresh information after editing

  useEffect(() => {
    // if a firm/firmadmin user wasn't authorized to see the requested supporticle, or its slug was invalid, return to /cases
    if (singleSupporticleSearchError && user.roles.indexOf('firm') > -1) {
      router.push('/cases')
    } // if they're not a firm/firmadmin user, they'll see Add New (if they're Admin) and Back to Support buttons
  }, [singleSupporticleSearchError, router, user])

  // Builds the categories and subcategories for the autoCompletes in the supporticleEdit.js
  useEffect(() => {
    if (supportRows.length) {
      const categories = supportRows.map(supporticle => supporticle.categories).flat()
      const uniqueCategories = [...new Set(categories)]
      const allCategories = []
      for (const category of uniqueCategories) {
        const supporticlesWithCategory = supportRows.filter(supporticle => supporticle.categories.includes(category))
        const subCategories = supporticlesWithCategory.map(supporticle => supporticle.subCategories).flat()
        const uniqueSubCategories = [...new Set(subCategories)]
        allCategories.push({ category, subCategories: uniqueSubCategories })
      }
      setSelectableCategories(allCategories)
    }
  }, [supportRows])

  return (
    <Paper elevation={5} style={style} >
      {/* if ispermissions.includes('create:supporticle') and not inDrawer, show the big Add Support Article button */}
      {user?.permissions.includes('create:supporticle') && !inDrawer &&
        <Fab
          className='qa-button-support-add-support-article'
          variant='extended'
          aria-label='add'
          color='secondary'
          style={{ width: '100%', marginTop: '1em', marginBottom: '1em' }}
          onClick={() => handleShowSupporticleEditOpen()}
        >
          <AddOutlined style={{ marginRight: '0.5em' }} />
          Add Support Article
        </Fab>}
      {/* show supporticle edit FSD */}
      {showSupporticleEditFSD &&
        <Dialog
          fullScreen
          open={showSupporticleEditFSD}
          TransitionComponent={Transition}
        >
          <SupporticleEdit
            originalSupporticle={supporticleToEdit}
            onShowSupporticleEditClose={handleShowSupporticleEditClose}
            query={multipleSupporticlesQuery}
            rowsPerPage={rowsPerPage}
            selectableCategories={selectableCategories}
          />
        </Dialog>}
      <DataGridPremium
        columns={supportColumns}
        rows={supportRows}
        apiRef={apiRef}
        autoHeight
        sx={{ width: inDrawer ? 700 : '100%' }}
        getRowId={(row) => row.slug}
        initialState={initialState}
        groupingColDef={{
          headerName: 'Categories',
          width: 200
        }}
        components={{
          Toolbar: RIGridToolBar,
          DetailPanelExpandIcon: ChevronRight,
          DetailPanelCollapseIcon: ExpandMore
        }}
        getDetailPanelContent={getDetailPanelContent} // To change content of detail panel, edit ./QuickView/GridQuickView.js
        getDetailPanelHeight={() => inDrawer ? 500 : 825}
      />
    </Paper>
  )
}

export default SupporticleController
