import { Parser as Json2csvParser } from 'json2csv'

/**
   * creates .csv file from JSON
   * @param {Object} json
   */
const makeCSV = (json) => {
  try {
    // Strip illegal characters
    // const newJson = {}
    // for (let [key, value] of json.entries()) {
    //   // #
    //   key = key.replace('#', String())
    //   value = value.replace('#', String())
    //   newJson[key] = value
    // }
    const parser = new Json2csvParser()
    const csv = parser.parse(json)
    return csv
  } catch (error) {
    console.error('makeCSV error: ', error.message)
  }
}

module.exports = {
  makeCSV
}
