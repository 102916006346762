/* eslint-disable no-unused-vars */
import {
  consoleATTN, consoleDev, consoleWarn, consoleError, delay, delayCountdown, clientWindow, deepCopy,
  jsonStr, isEmptyObject, consoleInfo, jsonPar, immutableObjUpdate, getRandomIntInclusive
} from './helper'
const thisFile = 'helpers/localStorageHelper '
/* eslint-enable no-unused-vars */

const labels = {
  lastFilter: '_lastFilter',
  fifo: '_fifo'
}

const MAX_FIFO_LENGTH = 5

/**
 *  Root function to remove item from local storage
 *
 * @param {*} label
 */
const deleteByLabel = (label) => {
  clientWindow?.localStorage.removeItem(label)
}

/**
 *
 * Root function to get item from local storage
 *
 * @param {*} label
 * @returns
 */
const getByLabel = (label) => {
  let returnValue
  try {
    returnValue = process.env.NODE_ENV !== 'test' && jsonPar(clientWindow?.localStorage.getItem(label))
  } catch (error) {
    consoleError(thisFile, 'getByLabel error:', error.message)
  }
  return returnValue
}

// Use-specific wrappers for labeling
const getOppFifo = (userId) => {
  let fifoArray
  try {
    fifoArray = process.env.NODE_ENV === 'test' ? [] : getByLabel(userId + '_opp' + labels.fifo)
  } catch (error) {
    consoleError(thisFile, 'getByLabel error:', error.message)
  }
  return fifoArray
}
const getCaseFifo = (userId) => {
  let fifoArray
  try {
    fifoArray = process.env.NODE_ENV === 'test' ? [] : jsonPar(getByLabel(userId + '_case' + labels.fifo))
  } catch (error) {
    consoleError(thisFile, 'getByLabel error:', error.message)
  }
  return fifoArray
}

// Unlimited switch for labels
const getFifoByView = (view, email) => {
  switch (view) {
    case 'opp':
      return getOppFifo(email)
    case 'case':
      return getCaseFifo(email)
  }
}

/**
 * Gets fifo array from local storage
 *
 * @param {*} view
 * @param {*} email
 */
export const getFifoOnOpen = (view, email) => {
  return getFifoByView(view, email)
}

/**
 *
 * Gets the last filter from local storage
 *
 * @param {*} label
 * @returns
 */

export const getLastFilter = () => {
  let lastFilter
  try {
    lastFilter = getByLabel(labels.lastFilter)
  } catch (error) {
    consoleError(thisFile, 'getLastFilter error:', error.message)
  }
  return lastFilter
}

/**
  *
  *
  * @param {boolean} [clear=true] will remove the key
  * @returns
  */
export const getRedirect = (clear = true) => {
  try {
    const returnValue = getByLabel('redirect')
    if (clear) deleteByLabel('redirect')
    return returnValue
  } catch (error) {
    consoleError(thisFile, 'getRedirect error:', error.message)
  }
}

/**
 *
 * Root function to set value in local storage.
 *
 * @param {*} label
 * @param {*} fifoArray
 */
const setByLabel = (label, value) => process.env.NODE_ENV !== 'test' && clientWindow?.localStorage.setItem(label, jsonStr(value))

/**
 *
 * Puts the fifo array into local storage based on label
 *
 * @param {*} label
 * @param {*} fifoArray
 */
const setFifo = (label, fifoArray) => process.env.NODE_ENV !== 'test' && setByLabel(label + labels.fifo, fifoArray)

const setOppFifo = (userId, fifoArray) => {
  setFifo(userId + '_opp', fifoArray)
}
const setCaseFifo = (userId, fifoArray) => {
  setFifo(userId + '_case', fifoArray)
}

// Unlimited switch for labels
const setFifoByView = (view, email, fifoArray) => {
  switch (view) {
    case 'opp':
      return setOppFifo(email, fifoArray)
    case 'case':
      return setCaseFifo(email, fifoArray)
  }
}

/**
 * Sets fifo array to local storage
 *
 * @param {*} view
 * @param {*} email
 * @param {*} dId
 * @returns
 */
export const setFifoOnClose = (view, email, dId) => {
  const oppFifoLocalStorage = getFifoByView(view, email)
  if (oppFifoLocalStorage) {
    // consoleDev(thisFile, 'setFifoOnClose oppFifoLocalStorage:', oppFifoLocalStorage)
    const existingIndex = oppFifoLocalStorage.indexOf(dId)
    if (existingIndex > -1) {
      // remove if exists because added to end (fifo) below
      oppFifoLocalStorage.splice(existingIndex, 1)
    }
    oppFifoLocalStorage.push(dId)
    if (oppFifoLocalStorage.length > MAX_FIFO_LENGTH) {
      oppFifoLocalStorage.shift()
    }
    setFifoByView(view, email, oppFifoLocalStorage)
    return oppFifoLocalStorage
  } else {
    setFifoByView(view, email, [dId])
    return [dId]
  }
}

/**
 *
 * Puts the last search filter into local storage
 *
 * @param {*} label
 * @param {*} fifoArray
 */
export const setLastFilter = (lastFilter) => process.env.NODE_ENV !== 'test' && setByLabel(labels.lastFilter, lastFilter)

/**
 *
 * Puts the redirect url into local storage
 *
 * @param {*} url
 */
export const setRedirect = (url) => setByLabel('redirect', url)

export const deleteLastFilter = () => {
  deleteByLabel(labels.lastFilter)
}
