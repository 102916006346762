import { DataGridPremium } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import { baseUrl, consoleError } from '../../src/helpers/helper'

const thisFile = 'components/Common/AutoAssignUserQueue.js ' // eslint-disable-line no-unused-vars

const AutoAssignUserQueue = (props) => {
  const {
    user,
    headerStyles
  } = props

  const [autoAssignQueueColumns, setAutoAssignQueueColumns] = useState([])
  const [autoAssignQueueRows, setAutoAssignQueueRows] = useState([])
  const [loadingAutoAssignQueue, setLoadingAutoAssignQueue] = useState(false)

  const getQueue = async () => {
    try {
      setLoadingAutoAssignQueue(true)
      const autoAssignUsersQueueEndpoint = new URL('/api/autoAssignUsersQueue', baseUrl)
      autoAssignUsersQueueEndpoint.search = new URLSearchParams({
        apiToken: process.env.CASEOPP_AUTH_TOKEN
      })
      const response = await fetch(autoAssignUsersQueueEndpoint.toString(), {
        method: 'GET'
      })
      const parsedResponse = await response.json()
      if (response.status === 200) {
        setAutoAssignQueueRows(parsedResponse)
      } else {
        setLoadingAutoAssignQueue(false)
      }
      setLoadingAutoAssignQueue(false)
    } catch (error) {
      setLoadingAutoAssignQueue(false)
      consoleError(thisFile, 'getQueue error:', error)
    }
  }
  useEffect(() => {
    getQueue()
  }, [])

  useEffect(() => {
    setAutoAssignQueueColumns([
      {
        field: 'name',
        headerName: 'Name',
        width: 250,
        cellClassName: (params) => (params.row.user_id === user.user_id ? headerStyles.currentUser : '')
      },
      {
        field: 'phoneStatus',
        headerName: 'Phone Status',
        width: 200,
        valueGetter: (params) => {
          return params.row.app_metadata.phoneStatus
        }
      },
      {
        field: 'lastAssigned',
        headerName: 'Last Assigned',
        width: 200,
        type: 'dateTime',
        valueGetter: (params) => {
          return (params.row.app_metadata.lastAssigned ? new Date(params.row.app_metadata.lastAssigned) : null)
        }
      }
    ])
  }, [headerStyles, user])

  return (

    <div id='autoAssignUsersQueue' style={{ height: '92vh', width: '100%' }}>
      <DataGridPremium
        columns={autoAssignQueueColumns}
        rows={autoAssignQueueRows}
        loading={loadingAutoAssignQueue}
        rowHeight={38}
        disableMultipleSelection
        pagination
        autoPageSize
        disableSelectionOnClick
        getRowClassName={(params) => (~params.row.app_metadata.phoneStatus?.indexOf('Offline') ? headerStyles?.offline : headerStyles?.available)}
        density='compact'
        componentsProps={{
          filterPanel: {
            columnsSort: 'asc'
          }
        }}
      />
    </div>
  )
}

export default AutoAssignUserQueue
