const supportPermissions = [
  { displayName: 'Admin', value: 'read:adminSupporticle' },
  { displayName: 'Firm Admin', value: 'read:firmadminSupporticle' },
  { displayName: 'Firm', value: 'read:firmSupporticle' },
  { displayName: 'Intake Agent', value: 'read:intakeSupporticle' },
  { displayName: 'Case Manager', value: 'read:caseManagerSupporticle' },
  { displayName: 'Paralegal', value: 'read:paralegalSupporticle' },
  { displayName: 'Attorney', value: 'read:paralegalSupporticle' },
  { displayName: 'Super Admin', value: 'read:superAdminSupporticle' },
  { displayName: 'Supervisor', value: 'read:supervisorSupporticle' }
]

module.exports = {
  supportPermissions
}
