import React, { useState, useEffect } from 'reactn'
import classNames from 'classnames'
// MUI Core
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import Tooltip from '@mui/material/Tooltip'

import { ChevronRight } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import SupporticleController from '../Support/SupporticleController'
import supportStyles from './styles/support.module.css'

const Support = (props) => {
  const [supportDrawerOpen, setSupportDrawerOpen] = useState(false)
  const [clientWindow, setClientWindow] = useState()
  const [searchFromPath, setSearchFromPath] = useState()
  const helpHandleDrawerOpen = () => {
    setSupportDrawerOpen(true)
  }
  const helpHandleDrawerClose = () => {
    setSupportDrawerOpen(false)
  }

  useEffect(() => {
    setClientWindow((typeof window !== 'undefined') ? window : undefined)
  }, [])

  useEffect(() => {
    if (clientWindow) {
      const splitPath = clientWindow?.location.pathname.split('/')
      setSearchFromPath(splitPath[splitPath.length - 1])
    }
  }, [clientWindow])

  return (
    <div>

      <IconButton
        color='inherit'
        aria-label='Open drawer'
        onClick={helpHandleDrawerOpen}
        className={classNames(supportStyles.menuButton, 'qa-button-header-support', {
          [supportStyles.hide]: supportDrawerOpen
        })}
        size="large">
        <Tooltip title='Support' placement='bottom'>
          <HelpIcon width='64px' height='64px' />
        </Tooltip>
      </IconButton>

      <Drawer
        variant='temporary'
        open={supportDrawerOpen}
        onClose={helpHandleDrawerClose}
        anchor='right'
        style={{ zIndex: 3000 }}
      >
        <div className={supportStyles.toolbar}>
          <IconButton
            className='qa-support-drawer-close'
            onClick={helpHandleDrawerClose}
            size="large">
            <ChevronRight />
          </IconButton>
          <SupporticleController
            user={props.user}
            defaultSearch={props.defaultSearch || searchFromPath}
            campaignSlug={props.campaignSlug}
            inDrawer
          />
        </div>
      </Drawer>
    </div>
  )
}

export default (Support)
