import { useState, useEffect } from 'reactn'
// MUI Core
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'

// import { newBugMutation } from '../../src/gql/bugReportGQL'
import { consoleError, clientWindow, consoleDev, isEmptyObject, scrubTypename, consoleATTN, hasAccess } from '../../src/helpers/helper' // eslint-disable-line no-unused-vars
import { getAllUsers } from '../../src/helpers/userHelper' // eslint-disable-line no-unused-vars
const thisFile = 'components/Common/BugReportForm ' // eslint-disable-line no-unused-vars

const BugReportForm = (props) => {
  const {
    bugToEdit,
    bugToView,
    openBugExpandedView,
    setOpenBugExpandedView,
    mutateBug, // this is create bug mutation unless bugController is passing in an already existing bug
    setRefetchList = () => {}, // this only exists if the form is being called from the BugController
    setBugToEdit = () => {},
    setBugToView = () => {},
    user,
    accessRules,
    showAuthorField = true
  } = props
  const [bug, setBug] = useState({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
  const [bugChanges, setBugChanges] = useState({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
  const [row, setRow] = useState(2)
  const [buttonText, setButtonText] = useState('Save')
  const [users, setUsers] = useState([])
  const [showAuthor, setShowAuthor] = useState('')
  const [showJiraIssue, setShowJiraIssue] = useState('')

  const handleBugChange = name => event => { // this function updates state for the bug object being created/edited
    const newValue = event.target.value
    if (newValue?.endsWith('\n') && name === 'steps') { // with this when they hit enter in the steps textbox it auto adds a number for them
      setBug(prev => ({ ...prev, [name]: newValue + row + '. ' }))
      setBugChanges(prev => ({ ...prev, [name]: newValue + row + '. ' }))
      setRow(prev => prev + 1)
    } else {
      setBug(prev => ({ ...prev, [name]: event.target.value }))
      setBugChanges(prev => ({ ...prev, [name]: event.target.value }))
    }
  }
  useEffect(() => { // this effect is for the author name
    if (bug?.userId) {
      setShowAuthor(users?.find(aUser => aUser.user_id === bug?.userId)?.name || bug?.userId)
    } else if (user?.name) {
      setShowAuthor(user.name)
    }
  }, [bug?.userId, users, user?.name])

  // set Jira Number for admins and superAdmins
  useEffect(() => {
    if (bug?.jiraIssue) {
      setShowJiraIssue(bug?.jiraIssue)
    } else {
      setShowJiraIssue('')
    }
  }, [bug?.jiraIssue, showJiraIssue])

  // get all users from redis cache
  useEffect(() => {
    getAllUsers(setUsers)
  }, [])

  useEffect(() => {
    if (!isEmptyObject(bugToEdit) && bugToEdit?.id) {
      setBug(bugToEdit)
      setBugChanges({ id: bugToEdit.id, type: bugToEdit.type })
    } else if (!isEmptyObject(bugToView)) {
      setBug(bugToView)
    }
  }, [bugToEdit, bugToView])

  // get computer os and instructions on how to screenshot
  const GetScreenshotInstructions = () => {
    const os = clientWindow.navigator.platform
    let instructionString = 'To take a screenshot '
    let instructionLink = ''
    if (os.includes('Win')) {
      instructionString += 'press Windows key + Shift + S, or use the snipping tool program'
      instructionLink = 'https://support.microsoft.com/en-us/windows/use-snipping-tool-to-capture-screenshots-00246869-1843-655f-f220-97299b865f6b'
    } else if (os.includes('Mac')) {
      instructionString += 'press Shift + Command + 3'
      instructionLink = 'https://support.apple.com/en-us/HT201361'
    } else if (os.includes('CrOS')) {
      instructionString += 'press Ctrl+P, or use the "Print" button in the Chrome menu'
      instructionLink = 'https://support.google.com/chromebook/answer/10474268?hl=en'
    }
    return (
      <>
        <Typography
          variant='subtitle2'
          color='secondary'
        >
          Please take a screenshot of your console and attach to the bug report in Slack.
        </Typography>
        <Typography
          variant='caption'
          color='primary'
        >
          To open your console, right click on the page and select Inspect Element. Then select the Console tab.
        </Typography>
        <br />
        <Typography
          variant='caption'
          color='primary'
        >
          {instructionString}. For more tips on screenshots see <a href={instructionLink} target='_blank' rel='noopener noreferrer'>here</a>.
        </Typography>
      </>
    )
  }

  return (
    <Dialog
      style={{ zIndex: 100000 }}
      open={openBugExpandedView}
      onBackdropClick={() => {
        setOpenBugExpandedView(false)
        setBugToEdit({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
        setBug({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
        setBugToView({})
      }}>
      <DialogContent>
        <Typography component='h4' variant='h4' color='secondary' gutterBottom>Report Bug</Typography>
        <TextField
          required
          fullWidth
          id='featureTitle'
          label={`Brief Description of Bug`}
          variant='outlined'
          value={bug.title || ''}
          onChange={handleBugChange('title')}
          margin='normal'
          disabled={!isEmptyObject(bugToView)}
          style={!bug.title ? { backgroundColor: 'rgba(255,0,0,.15)' } : {}}
        />
        <>
          <TextField
            required
            fullWidth
            id='bug-replication-steps'
            label='Replication Steps'
            variant='outlined'
            multiline
            value={bug?.steps || ''}
            margin='normal'
            rows={3}
            rowsMax={20}
            onFocus={(event) => {
              event.target.setSelectionRange(event.target.value.length, event.target.value.length) // set the cursor to the end of the input on focus
            }}
            onChange={handleBugChange('steps')}
            disabled={!isEmptyObject(bugToView)}
            style={(!bug.steps || bug.steps === '1. ') ? { backgroundColor: 'rgba(255,0,0,.15)' } : {}}
          />
          <TextField
            required
            fullWidth
            id='bug-expected-result'
            label='What did you expect to happen?'
            variant='outlined'
            multiline
            rows={2}
            margin='normal'
            value={bug?.expected || ''}
            onChange={handleBugChange('expected')}
            disabled={!isEmptyObject(bugToView)}
            style={!bug.expected ? { backgroundColor: 'rgba(255,0,0,.15)' } : {}}
          />
          <TextField
            required
            fullWidth
            id='bug-observed-result'
            label='What actually happened?'
            variant='outlined'
            multiline
            rows={2}
            margin='normal'
            value={bug?.observed || ''}
            onChange={handleBugChange('observed')}
            disabled={!isEmptyObject(bugToView)}
            style={!bug.observed ? { backgroundColor: 'rgba(255,0,0,.15)' } : {}}
          />
          <TextField
            fullWidth
            id='bug-url'
            label='Where did this happen?'
            margin='normal'
            helperText='for example URL (pro.caseopp.com/opportunities/AVA-0001/clientInfo) or specific opp or page'
            value={bug?.url || clientWindow.location.href}
            onChange={handleBugChange('url')}
            disabled={!isEmptyObject(bugToView)}
          />
          <TextField
            fullWidth
            id='bug-loom-link'
            label='Attach Loom Video link'
            margin='normal'
            helperText='If you recorded a loom video you can add the link here'
            value={bug?.loomLink || ''}
            onChange={handleBugChange('loomLink')}
            disabled={!isEmptyObject(bugToView)}
          />
        </>
        {hasAccess('action:updateBug', accessRules, user) &&
          <TextField
            fullWidth
            id='jiraIssue'
            label='Jira Issue'
            margin='normal'
            value={showJiraIssue}
            helperText={showJiraIssue}
            disabled={!isEmptyObject(bugToView) || !user?.permissions?.includes('update:jira')}
            onChange={handleBugChange('jiraIssue')}
          />}
        {showAuthorField &&
          <TextField
            fullWidth
            id='bug-author'
            margin='normal'
            helperText='Author of this bug/feature request'
            value={showAuthor}
            disabled
          />}
        <GetScreenshotInstructions />

      </DialogContent>
      <DialogActions>
        {isEmptyObject(bugToView) && <Button
          color='secondary'
          variant='outlined'
          onClick={() => {
            try {
              const changes = isEmptyObject(bugToEdit) && isEmptyObject(bugToView) ? bug : bugChanges
              mutateBug(changes)
            } catch (error) {
              setButtonText('Error reporting bug, please submit manually.')
            }
            setOpenBugExpandedView(false)
            setBug({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
            setBugChanges({})
            setRefetchList(true)
          }}
        >
          {buttonText}
        </Button>}
        <Button
          color='secondary'
          variant='outlined'
          onClick={() => {
            setOpenBugExpandedView(false)
            setBugToEdit({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
            setBug({ type: 'bug', url: clientWindow.location.href, steps: '1. ' })
            setBugToView({})
          }}
        >Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BugReportForm
