import { useEffect, useState } from 'react'

export default function useDeBounce (value, delay) {
// State and setters for deBounced value
  const [deBouncedValue, setDeBouncedValue] = useState(value)

  useEffect(
    () => {
    // Update deBounced value after delay
      const handler = setTimeout(() => {
        setDeBouncedValue(value)
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent deBounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )

  return deBouncedValue
}
